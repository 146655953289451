:root {
    /* ------------------------ font ------------------------ */
    --font-family-base: Roboto, "Helvetica Neue", Helvetica, Tahoma, Arial, "PingFang SC", "Microsoft YaHei";
    --font-weight-ultra-bold: 900;
    --font-weight-extra-bold: 800;
    --font-weight-3: bold;
    --font-weight-semi-bold: 600;
    --font-weight-medium: 500;
    --font-weight-2: normal;
    --font-weight-light: 300;
    --font-weight-thin: 200;
    --font-weight-1: lighter;
    --font-size-body-2: 14px;
    --font-size-subhead: 16px;
    --font-size-title: 20px;
    --font-size-headline: 24px;
    --font-size-display-1: 36px;
    --font-size-display-2: 48px;
    --font-size-display-3: 56px;
    --font-lineheight-3: 1.7;
    --font-lineheight-2: 1.5;
    --font-lineheight-1: 1.3;
    --font-size-caption: 12px;
    --font-size-body-1: 14px;
    /* ------------------------ line ------------------------ */
    --line-dotted: dotted;
    --line-dashed: dashed;
    --line-solid: solid;
    --line-3: 3px;
    --line-2: 2px;
    --line-1: 1px;
    --line-zero: 0px;
    /* ------------------------ popup ------------------------ */
    --popup-global-shadow: var(--shadow-2-down, 0px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --popup-global-corner: var(--corner-1, 3px);
    --popup-global-border-color: var(--color-line1-2, #E6E6E6);
    --popup-global-border-style: var(--line-solid, solid);
    --popup-global-border-width: var(--line-zero, 0px);
    --popup-local-shadow: var(--shadow-zero, none);
    --popup-local-corner: var(--corner-1, 3px);
    --popup-local-border-color: var(--color-line1-2, #E6E6E6);
    --popup-local-border-style: var(--line-solid, solid);
    --popup-local-border-width: var(--line-1, 1px);
    --popup-spacing-tb: var(--s-zero, 0px);
    --popup-spacing-lr: var(--s-zero, 0px);
    /* ------------------------ mask ------------------------ */
    --mask-background: var(--color-black, #000000);
    --mask-opacity: 0.3;
    /* ------------------------ form-element ------------------------ */
    --form-element-preview-text-color: var(--color-text1-4, #333333);
    --form-element-large-corner: var(--corner-1, 3px);
    --form-element-medium-corner: var(--corner-1, 3px);
    --form-element-small-corner: var(--corner-1, 3px);
    --form-element-large-preview-font-size: var(--font-size-subhead, 16px);
    --form-element-large-preview-label-font-size: var(--font-size-subhead, 16px);
    --form-element-medium-preview-font-size: var(--font-size-body-1, 14px);
    --form-element-medium-preview-label-font-size: var(--font-size-body-1, 14px);
    --form-element-small-preview-font-size: var(--font-size-caption, 12px);
    --form-element-small-preview-label-font-size: var(--font-size-caption, 12px);
    --form-element-large-preview-height: var(--s-10, 40px);
    --form-element-medium-preview-height: var(--s-7, 28px);
    --form-element-small-preview-height: var(--s-5, 20px);
    --form-element-large-font-size: var(--font-size-subhead, 16px);
    --form-element-small-font-size: var(--font-size-caption, 12px);
    --form-element-large-height: var(--s-10, 40px);
    --form-element-preview-label-color: var(--color-text1-3, #666666);
    --form-element-large-icon-size: var(--icon-m, 20px);
    --form-element-medium-icon-size: var(--icon-m, 20px);
    --form-element-small-icon-size: var(--icon-s, 16px);
    --form-element-medium-font-size: var(--font-size-body-2, 14px);
    --form-element-medium-height: var(--s-8, 32px);
    --form-element-small-height: var(--s-6, 24px);
    /* ------------------------ size ------------------------ */
    --s-50: 200px;
    --s-49: 196px;
    --s-48: 192px;
    --s-47: 188px;
    --s-46: 184px;
    --s-45: 180px;
    --s-44: 176px;
    --s-43: 172px;
    --s-42: 168px;
    --s-41: 164px;
    --s-40: 160px;
    --s-39: 156px;
    --s-38: 152px;
    --s-37: 148px;
    --s-36: 144px;
    --s-35: 140px;
    --s-34: 136px;
    --s-33: 132px;
    --s-32: 128px;
    --s-31: 124px;
    --s-30: 120px;
    --s-29: 116px;
    --s-28: 112px;
    --s-27: 108px;
    --s-26: 104px;
    --s-25: 100px;
    --s-24: 96px;
    --s-23: 92px;
    --s-22: 88px;
    --s-21: 84px;
    --s-20: 80px;
    --s-19: 76px;
    --s-18: 72px;
    --s-17: 68px;
    --s-16: 64px;
    --s-15: 60px;
    --s-14: 56px;
    --s-13: 52px;
    --s-12: 48px;
    --s-11: 44px;
    --s-10: 40px;
    --s-9: 36px;
    --s-8: 32px;
    --s-7: 28px;
    --s-6: 24px;
    --s-5: 20px;
    --s-4: 16px;
    --s-3: 12px;
    --s-2: 8px;
    --s-1: 4px;
    --s-zero: 0px;
    --size-base: 4px;
    /* ------------------------ shadow ------------------------ */
    --shadow-3-left: -20px 0px 30px 0px rgba(0, 0, 0, 0.15);
    --shadow-3-down: 0px 20px 30px 0px rgba(0, 0, 0, 0.15);
    --shadow-3-right: 20px 0px 30px 0px rgba(0, 0, 0, 0.15);
    --shadow-3-up: 0px -20px 30px 0px rgba(0, 0, 0, 0.15);
    --shadow-3: 20px 20px 30px 0px rgba(0, 0, 0, 0.15);
    --shadow-2-left: -4px 0px 8px 0px rgba(0, 0, 0, 0.12);
    --shadow-2-down: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    --shadow-2-right: 4px 0px 8px 0px rgba(0, 0, 0, 0.12);
    --shadow-2-up: 0px -4px 8px 0px rgba(0, 0, 0, 0.12);
    --shadow-2: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
    --shadow-1-left: -1px 0px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-1-down: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-1-right: 1px 0px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-1-up: 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-1: 1px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-zero: none;
    --shadow-spread-sd3: 0;
    --shadow-spread-sd2: 0;
    --shadow-spread-sd1: 0;
    --shadow-blur-sd1: 3;
    --shadow-color-sd3: var(--color-black, #000000);
    --shadow-color-sd2: var(--color-black, #000000);
    --shadow-color-sd1: var(--color-black, #000000);
    --shadow-opacity-sd2: .12;
    --shadow-opacity-sd1: .12;
    --shadow-distance-sd1y: 1;
    --shadow-distance-sd1: 1;
    --shadow-sides-left: "left";
    --shadow-sides-down: "down";
    --shadow-sides-right: "right";
    --shadow-sides-up: "up";
    --shadow-sides-base: "base";
    --shadow-blur-sd2: 8;
    --shadow-distance-sd2y: 4;
    --shadow-distance-sd2: 4;
    --shadow-blur-sd3: 30;
    --shadow-opacity-sd3: 0.15;
    --shadow-distance-sd3y: 20;
    --shadow-distance-sd3: 20;
    /* ------------------------ icon ------------------------ */
    --icon-reset: "";
    --icon-xxxl: var(--s-16, 64px);
    --icon-xxl: var(--s-12, 48px);
    --icon-xl: var(--s-8, 32px);
    --icon-l: var(--s-6, 24px);
    --icon-m: var(--s-5, 20px);
    --icon-s: var(--s-4, 16px);
    --icon-xs: var(--s-3, 12px);
    --icon-xxs: var(--s-2, 8px);
    --icon-font-name: "icon";
    --icon-content-dashboard: "\e7fa";
    --icon-content-list: "\e7f9";
    --icon-content-detail: "\e7f8";
    --icon-content-form: "\e7fb";
    --icon-font-path: "//at.alicdn.com/t/font_1533967_slipq25tezj";
    --icon-content-ic_yusuanguanli: "\e745";
    --icon-content-ic_formbeifen: "\e7e0";
    --icon-content-ic_form: "\e7e1";
    --icon-content-ic_dashboard: "\e7e2";
    --icon-content-ic_tongzhi: "\e749";
    --icon-content-ic_logo: "\e79d";
    --icon-content-taobao: "\e7be";
    --icon-content-alibaba: "\e7bf";
    --icon-content-ic_language: "\e7da";
    --icon-content-chart-pie: "\e613";
    --icon-content-chart-bar: "\e612";
    --icon-content-exit: "\e616";
    --icon-content-lock: "\e617";
    --icon-content-unlock: "\e615";
    --icon-content-eye-close: "\e600";
    --icon-content-eye: "\e611";
    --icon-content-toggle-right: "\e603";
    --icon-content-toggle-left: "\e602";
    --icon-content-copy: "\e60f";
    --icon-content-atm: "\e606";
    --icon-content-email: "\e605";
    --icon-content-account: "\e608";
    --icon-content-attachment: "\e665";
    --icon-content-filter: "\e627";
    --icon-content-refresh: "\e677";
    --icon-content-edit: "\e63b";
    --icon-content-set: "\e683";
    --icon-content-download: "\e628";
    --icon-content-upload: "\e7ee";
    --icon-content-ashbin: "\e639";
    --icon-content-calendar: "\e607";
    --icon-content-picture: "\e631";
    --icon-content-ellipsis: "\e654";
    --icon-content-close: "\e626";
    --icon-content-search: "\e656";
    --icon-content-loading: "\e646";
    --icon-content-semi-select: "\e633";
    --icon-content-select: "\e632";
    --icon-content-ascending: "\e61e";
    --icon-content-descending: "\e61f";
    --icon-content-sorting: "\e634";
    --icon-content-switch: "\e6b3";
    --icon-content-arrow-double-right: "\e65e";
    --icon-content-arrow-double-left: "\e659";
    --icon-content-arrow-right: "\e619";
    --icon-content-arrow-left: "\e61d";
    --icon-content-arrow-down: "\e63d";
    --icon-content-arrow-up: "\e625";
    --icon-content-minus: "\e601";
    --icon-content-add: "\e655";
    --icon-content-favorites-filling: "\e60e";
    --icon-content-delete-filling: "\e623";
    --icon-content-success-filling: "\e63a";
    --icon-content-clock: "\e621";
    --icon-content-help: "\e673";
    --icon-content-error: "\e60d";
    --icon-content-prompt: "\e60c";
    --icon-content-warning: "\e60b";
    --icon-content-success: "\e60a";
    --icon-content-cry: "\e65d";
    --icon-content-smile: "\e65f";
    /* ------------------------ corner ------------------------ */
    --corner-zero: 0px;
    --corner-semicircle: 500px;
    --corner-circle: 50%;
    --corner-2: 6px;
    --corner-1: 3px;
    --corner-3: 12px;
    /* ------------------------ color ------------------------ */
    --color-text1-4: #333333;
    --color-text1-3: #666666;
    --color-text1-2: #999999;
    --color-gradient-4: linear-gradient(270deg, rgb(255, 163, 166) 0%, rgb(245, 39, 67) 100%);
    --color-gradient-3: linear-gradient(270deg, rgb(255, 237, 117) 0%, rgb(245, 203, 34) 100%);
    --color-gradient-2: linear-gradient(270deg, rgb(125, 238, 255) 0%, rgb(3, 193, 253) 100%);
    --color-gradient-1: linear-gradient(270deg, rgb(121, 232, 199) 0%, rgb(8, 194, 158) 100%);
    --color-error-2: #FFC8B2;
    --color-error-1: #FFECE4;
    --color-transparent: transparent;
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-other-1: #8f2115;
    --color-notice-1: #E4F3FE;
    --color-brand1-1: #ADD9FF;
    --color-brand1-9: #1274E7;
    --color-fill1-6: #151515;
    --color-other-3: #111111;
    --color-other-2: #222222;
    --color-data1-9: #D23C26;
    --color-data1-8: #73D13D;
    --color-data1-7: #FFEC3D;
    --color-data1-6: #FFC53D;
    --color-data1-5: #FF4D4F;
    --color-data1-3: #2F54EB;
    --color-data1-2: #209BFA;
    --color-data1-1: #36CFC9;
    --color-brand1-6: #FF4D4F;
    --color-notice-3: #298DFF;
    --color-text1-5: #FFFFFF;
    --color-fill1-5: #222222;
    --color-data1-4: #A758E2;
    --color-text1-1: #CCCCCC;
    --color-fill1-4: #F2F2F2;
    --color-fill1-3: #F5F5F5;
    --color-fill1-2: #F9F9F9;
    --color-fill1-1: #FAFAFA;
    --color-line1-4: #CCCCCC;
    --color-line1-3: #DDDDDD;
    --color-line1-2: #E6E6E6;
    --color-line1-1: #EEEEEE;
    --color-link-3: #2580E7;
    --color-link-2: #4A83C5;
    --color-link-1: #D23C26;
    --color-error-4: #B7321E;
    --color-error-3: #D23C26;
    --color-notice-4: #1B84E0;
    --color-notice-2: #ADD9FF;
    --color-success-4: #10B976;
    --color-success-3: #1AD78C;
    --color-success-2: #88FFD0;
    --color-success-1: #E5FFF5;
    --color-help-4: #D69600;
    --color-help-3: #F1C826;
    --color-help-2: #FFE98F;
    --color-help-1: #FFF9E0;
    --color-warning-4: #D69600;
    --color-warning-3: #F1C826;
    --color-warning-2: #FFE98F;
    --color-warning-1: #FFF9E0;
    /* ------------------------ search ------------------------ */
    --search-normal-primary-border-width: var(--line-1, 1px);
    --search-normal-primary-l-height: var(--s-10, 40px);
    --search-normal-primary-m-height: var(--s-8, 32px);
    --search-normal-primary-m-icon-size: var(--icon-xs, 12px);
    --search-normal-secondary-l-height: var(--s-10, 40px);
    --search-normal-secondary-m-height: var(--s-8, 32px);
    --search-normal-secondary-m-icon-size: var(--icon-xs, 12px);
    --search-normal-normal-l-height: var(--s-10, 40px);
    --search-normal-normal-m-height: var(--s-8, 32px);
    --search-normal-normal-m-icon-size: var(--icon-xs, 12px);
    --search-normal-dark-bg-opacity: 0;
    --search-normal-dark-l-height: var(--s-10, 40px);
    --search-normal-dark-m-height: var(--s-8, 32px);
    --search-normal-dark-m-icon-size: var(--icon-xs, 12px);
    --search-simple-dark-color: var(--color-text1-3, #666666);
    --search-simple-dark-hover-color: var(--color-text1-2, #999999);
    --search-simple-dark-icon-text-color: var(--color-text1-3, #666666);
    --search-simple-dark-m-icon-size: var(--icon-m, 20px);
    --search-simple-m-icon-margin-right: var(--s-2, 8px);
    --search-simple-dark-text-color: var(--color-white, #FFFFFF);
    --search-simple-dark-icon-hover-text-color: var(--color-text1-2, #999999);
    --search-normal-corner: var(--corner-1, 3px);
    --search-normal-primary-color: var(--color-brand1-6, #209BFA);
    --search-normal-primary-hover-color: var(--color-brand1-6, #209BFA);
    --search-normal-primary-split-color: var(--color-line1-1, #EEEEEE);
    --search-normal-primary-shadow: var(--shadow-zero, none);
    --search-normal-primary-btn-text-color: var(--color-white, #FFFFFF);
    --search-normal-primary-btn-hover-bg-color: var(--color-brand1-9, #1274E7);
    --search-normal-primary-btn-hover-text-color: var(--color-white, #FFFFFF);
    --search-normal-primary-btn-text-padding-left: var(--s-zero, 0px);
    --search-normal-primary-l-icon-size: var(--icon-l, 24px);
    --search-normal-primary-l-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-primary-m-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-secondary-color: var(--color-line1-3, #DDDDDD);
    --search-normal-secondary-split-color: var(--color-line1-1, #EEEEEE);
    --search-normal-secondary-hover-color: var(--color-brand1-6, #209BFA);
    --search-normal-secondary-shadow: var(--shadow-zero, none);
    --search-normal-secondary-btn-bg-color: var(--color-brand1-6, #209BFA);
    --search-normal-secondary-btn-text-color: var(--color-white, #FFFFFF);
    --search-normal-secondary-btn-hover-bg-color: var(--color-brand1-9, #1274E7);
    --search-normal-secondary-btn-hover-text-color: var(--color-white, #FFFFFF);
    --search-normal-secondary-border-width: var(--line-1, 1px);
    --search-normal-secondary-btn-text-padding-left: var(--s-zero, 0px);
    --search-normal-secondary-l-icon-size: var(--icon-l, 24px);
    --search-normal-secondary-l-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-secondary-m-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-normal-color: var(--color-line1-3, #DDDDDD);
    --search-normal-normal-split-color: var(--color-line1-1, #EEEEEE);
    --search-normal-normal-hover-color: var(--color-line1-4, #CCCCCC);
    --search-normal-normal-shadow: var(--shadow-zero, none);
    --search-normal-normal-btn-bg-color: var(--color-fill1-1, #FAFAFA);
    --search-normal-normal-btn-text-color: var(--color-text1-3, #666666);
    --search-normal-normal-btn-hover-bg-color: var(--color-fill1-3, #F5F5F5);
    --search-normal-normal-btn-hover-text-color: var(--color-text1-4, #333333);
    --search-normal-normal-border-width: var(--line-1, 1px);
    --search-normal-normal-btn-text-padding-left: var(--s-zero, 0px);
    --search-normal-normal-l-icon-size: var(--icon-l, 24px);
    --search-normal-normal-l-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-normal-m-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-dark-color: var(--color-brand1-6, #209BFA);
    --search-normal-dark-hover-color: var(--color-brand1-6, #209BFA);
    --search-normal-dark-text-color: var(--color-white, #FFFFFF);
    --search-normal-dark-bg-color: var(--color-white, #FFFFFF);
    --search-normal-dark-shadow: var(--shadow-zero, none);
    --search-normal-dark-btn-text-color: var(--color-white, #FFFFFF);
    --search-normal-dark-btn-hover-bg-color: var(--color-brand1-9, #1274E7);
    --search-normal-dark-btn-hover-text-color: var(--color-white, #FFFFFF);
    --search-normal-dark-border-width: var(--line-1, 1px);
    --search-normal-dark-btn-text-padding-left: var(--s-zero, 0px);
    --search-normal-dark-l-icon-size: var(--icon-l, 24px);
    --search-normal-dark-l-btn-text-size: var(--font-size-subhead, 16px);
    --search-normal-dark-m-btn-text-size: var(--font-size-subhead, 16px);
    --search-simple-corner: var(--corner-1, 3px);
    --search-simple-l-icon-margin-right: var(--s-3, 12px);
    --search-simple-normal-color: var(--color-line1-3, #DDDDDD);
    --search-simple-normal-hover-color: var(--color-line1-4, #CCCCCC);
    --search-simple-normal-split-color: var(--color-line1-3, #DDDDDD);
    --search-simple-normal-shadow: var(--shadow-zero, none);
    --search-simple-normal-icon-text-color: var(--color-text1-2, #999999);
    --search-simple-normal-icon-hover-text-color: var(--color-text1-3, #666666);
    --search-simple-normal-border-width: var(--line-1, 1px);
    --search-simple-normal-l-icon-size: var(--icon-m, 20px);
    --search-simple-normal-m-icon-size: var(--icon-xs, 12px);
    --search-simple-normal-btn-text-padding-left: var(--s-zero, 0px);
    --search-simple-dark-bg-color: var(--color-white, #FFFFFF);
    --search-simple-dark-bg-opacity: 0;
    --search-simple-dark-shadow: var(--shadow-zero, none);
    --search-simple-dark-btn-text-padding-left: var(--s-zero, 0px);
    --search-simple-dark-split-color: var(--color-line1-3, #DDDDDD);
    --search-simple-dark-border-width: var(--line-1, 1px);
    --search-simple-dark-l-icon-size: var(--icon-m, 20px);
    --search-search-icon-content: var(--icon-content-search, "");
    /* ------------------------ switch ------------------------ */

    --switch-size-m-trigger: var(--s-6, 24px);
    --switch-size-m-width: var(--s-12, 48px);
    --switch-size-m-radius-container: var(--corner-3, 12px);
    --switch-size-m-radius-trigger: var(--corner-3, 12px);
    --switch-size-m-inner-icon: var(--s-4, 16px);
    --switch-size-m-trigger-padding-l: var(--s-2, 8px);
    --switch-size-m-trigger-padding-r: var(--s-2, 8px);
    --switch-size-s-width: var(--s-11, 44px);
    --switch-size-s-radius-container: var(--corner-3, 12px);
    --switch-size-s-trigger: var(--s-5, 20px);
    --switch-size-s-radius-trigger: var(--corner-3, 12px);
    --switch-size-s-inner-icon: var(--s-3, 12px);
    --switch-size-s-trigger-padding-l: var(--s-2, 8px);
    --switch-size-s-trigger-padding-r: var(--s-2, 8px);
    --switch-border-width-container: var(--line-2, 2px);
    --switch-border-width-trigger: var(--line-1, 1px);
    --switch-normal-on-bg-color: var(--color-brand1-6, #209BFA);
    --switch-hover-on-bg-color: var(--color-brand1-9, #1274E7);
    --switch-disabled-on-bg-color: var(--color-fill1-3, #F5F5F5);
    --switch-normal-on-trigger-bg-color: var(--color-white, #FFFFFF);
    --switch-hover-on-trigger-bg-color: var(--color-white, #FFFFFF);
    --switch-disabled-on-trigger-bg-color: var(--color-fill1-1, #FAFAFA);
    --switch-normal-on-color-font: var(--color-white, #FFFFFF);
    --switch-disabled-on-color-font: var(--color-text1-1, #CCCCCC);
    --switch-disabled-on-border-color: var(--color-line1-3, #DDDDDD);
    --switch-handle-on-border-color: var(--color-transparent, transparent);
    --switch-on-shadow: var(--shadow-1, 1px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --switch-normal-off-bg-color: var(--color-fill1-3, #F5F5F5);
    --switch-hover-off-bg-color: var(--color-fill1-3, #F5F5F5);
    --switch-disabled-off-bg-color: var(--color-fill1-3, #F5F5F5);
    --switch-normal-off-trigger-bg-color: var(--color-white, #FFFFFF);
    --switch-hover-off-trigger-bg-color: var(--color-white, #FFFFFF);
    --switch-disabled-off-trigger-bg-color: var(--color-fill1-1, #FAFAFA);
    --switch-handle-disabled-border-color: transparent;
    --switch-normal-off-color-font: var(--color-text1-2, #999999);
    --switch-disabled-off-color-font: var(--color-line1-3, #DDDDDD);
    --switch-handle-off-border-color: var(--color-transparent, transparent);
    --switch-normal-off-border-color: var(--color-fill1-3, #F5F5F5);
    --switch-hover-off-border-color: var(--color-fill1-3, #F5F5F5);
    --switch-off-shadow: var(--shadow-1, 1px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --switch-rtl-on-shadow: var(--shadow-1-left, -1px 0px 3px 0px rgba(0, 0, 0, 0.12));
    /* ------------------------ button ------------------------ */
    --btn-size-s-height: var(--s-6, 24px);
    --btn-size-m-height: var(--s-8, 32px);
    --btn-size-m-icon-size: var(--icon-m, 20px);
    --btn-size-m-icon-margin: var(--s-1, 4px);
    --btn-size-l-icon-size: var(--icon-m, 20px);
    --btn-size-s-padding: var(--s-4, 16px);
    --btn-size-m-padding: var(--s-5, 20px);
    --btn-size-l-padding: var(--s-6, 24px);
    --btn-shadow: var(--shadow-zero, none);
    --btn-shadow-hover: var(--shadow-zero, none);
    --btn-size-s-border-width: var(--line-1, 1px);
    --btn-size-s-font: var(--font-size-caption, 12px);
    --btn-size-s-icon-size: var(--icon-xs, 12px);
    --btn-size-s-icon-margin: var(--s-1, 4px);
    --btn-size-s-icon-split-size: var(--icon-xs, 12px);
    --btn-size-s-corner: var(--corner-1, 3px);
    --btn-size-m-border-width: var(--line-1, 1px);
    --btn-size-m-font: var(--font-size-body-1, 14px);
    --btn-size-m-icon-split-size: var(--icon-xs, 12px);
    --btn-size-m-corner: var(--corner-1, 3px);
    --btn-size-l-height: var(--s-10, 40px);
    --btn-size-l-border-width: var(--line-1, 1px);
    --btn-size-l-font: var(--font-size-subhead, 16px);
    --btn-size-l-icon-margin: var(--s-1, 4px);
    --btn-size-l-icon-split-size: var(--icon-s, 16px);
    --btn-size-l-corner: var(--corner-1, 3px);
    --btn-pure-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-pure-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-pure-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --btn-pure-normal-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-pure-normal-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-pure-normal-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --btn-pure-normal-color: var(--color-text1-4, #333333);
    --btn-pure-normal-color-hover: var(--color-text1-4, #333333);
    --btn-pure-normal-color-active: var(--color-text1-4, #333333);
    --btn-pure-normal-bg: var(--color-white, #FFFFFF);
    --btn-pure-normal-bg-hover: var(--color-fill1-2, #F9F9F9);
    --btn-pure-normal-bg-active: var(--color-fill1-2, #F9F9F9);
    --btn-pure-normal-border-color: var(--color-line1-3, #DDDDDD);
    --btn-pure-normal-border-color-hover: var(--color-line1-4, #CCCCCC);
    --btn-pure-normal-border-color-active: var(--color-line1-4, #CCCCCC);
    --btn-pure-normal-border-style: var(--line-solid, solid);
    --btn-pure-secondary-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-pure-secondary-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-pure-secondary-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --btn-pure-secondary-color: var(--color-brand1-6, #209BFA);
    --btn-pure-secondary-color-hover: var(--color-white, #FFFFFF);
    --btn-pure-secondary-color-active: var(--color-white, #FFFFFF);
    --btn-pure-secondary-bg: var(--color-white, #FFFFFF);
    --btn-pure-secondary-bg-hover: var(--color-brand1-9, #1274E7);
    --btn-pure-secondary-bg-active: var(--color-brand1-9, #1274E7);
    --btn-pure-secondary-border-color: var(--color-brand1-6, #209BFA);
    --btn-pure-secondary-border-color-hover: var(--color-brand1-9, #1274E7);
    --btn-pure-secondary-border-color-active: var(--color-brand1-9, #1274E7);
    --btn-pure-secondary-border-style: var(--line-solid, solid);
    --btn-pure-primary-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-pure-primary-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-pure-primary-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --btn-pure-primary-color: var(--color-white, #FFFFFF);
    --btn-pure-primary-color-hover: var(--color-white, #FFFFFF);
    --btn-pure-primary-color-active: var(--color-white, #FFFFFF);
    --btn-pure-primary-bg: var(--color-brand1-6, #209BFA);
    --btn-pure-primary-bg-hover: var(--color-brand1-9, #1274E7);
    --btn-pure-primary-bg-active: var(--color-brand1-9, #1274E7);
    --btn-pure-primary-border-color: var(--color-transparent, transparent);
    --btn-pure-primary-border-color-hover: var(--color-transparent, transparent);
    --btn-pure-primary-border-color-active: var(--color-transparent, transparent);
    --btn-pure-primary-border-style: var(--line-solid, solid);
    --btn-ghost-border-style: var(--line-solid, solid);
    --btn-ghost-dark-color: var(--color-white, #FFFFFF);
    --btn-ghost-dark-border-color: var(--color-white, #FFFFFF);
    --btn-ghost-dark-color-hover: var(--color-white, #FFFFFF);
    --btn-ghost-dark-bg: var(--color-transparent, transparent);
    --btn-ghost-dark-bg-opacity: 0;
    --btn-ghost-dark-bg-hover-rgb: var(--color-white, #FFFFFF);
    --btn-ghost-dark-bg-hover-opacity: .8;
    --btn-ghost-dark-border-color-hover: var(--color-white, #FFFFFF);
    --btn-ghost-dark-color-disabled-rgb: var(--color-white, #FFFFFF);
    --btn-ghost-dark-color-disabled-opacity: .4;
    --btn-ghost-dark-bg-disabled: var(--color-transparent, transparent);
    --btn-ghost-dark-border-color-disabled-rgb: var(--color-white, #FFFFFF);
    --btn-ghost-dark-border-color-disabled-opacity: .4;
    --btn-ghost-light-color: var(--color-text1-4, #333333);
    --btn-ghost-light-border-color: var(--color-text1-4, #333333);
    --btn-ghost-light-bg: var(--color-transparent, transparent);
    --btn-ghost-light-bg-opacity: 0;
    --btn-ghost-light-color-hover: var(--color-text1-2, #999999);
    --btn-ghost-light-border-color-hover: var(--color-text1-4, #333333);
    --btn-ghost-light-bg-hover-rgb: var(--color-black, #000000);
    --btn-ghost-light-bg-hover-opacity: .92;
    --btn-ghost-light-color-disabled-rgb: var(--color-black, #000000);
    --btn-ghost-light-color-disabled-opacity: .1;
    --btn-ghost-light-bg-disabled: var(--color-transparent, transparent);
    --btn-ghost-light-border-color-disabled-rgb: var(--color-black, #000000);
    --btn-ghost-light-border-color-disabled-opacity: .1;
    --btn-warning-border-style: var(--line-solid, solid);
    --btn-warning-primary-color: var(--color-white, #FFFFFF);
    --btn-warning-primary-color-hover: var(--color-white, #FFFFFF);
    --btn-warning-primary-color-active: var(--color-white, #FFFFFF);
    --btn-warning-primary-border-color: var(--color-error-3, #D23C26);
    --btn-warning-primary-border-color-hover: var(--color-error-4, #B7321E);
    --btn-warning-primary-border-color-active: var(--color-error-4, #B7321E);
    --btn-warning-primary-bg: var(--color-error-3, #D23C26);
    --btn-warning-primary-bg-hover: var(--color-error-4, #B7321E);
    --btn-warning-primary-bg-active: var(--color-error-4, #B7321E);
    --btn-warning-primary-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-warning-primary-border-color-disabled: var(--color-line1-2, #E6E6E6);
    --btn-warning-primary-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-warning-normal-color: var(--color-error-3, #D23C26);
    --btn-warning-normal-color-hover: var(--color-white, #FFFFFF);
    --btn-warning-normal-color-active: var(--color-white, #FFFFFF);
    --btn-warning-normal-border-color: var(--color-error-3, #D23C26);
    --btn-warning-normal-border-color-hover: var(--color-error-4, #B7321E);
    --btn-warning-normal-border-color-active: var(--color-error-4, #B7321E);
    --btn-warning-normal-bg: var(--color-white, #FFFFFF);
    --btn-warning-normal-bg-hover: var(--color-error-4, #B7321E);
    --btn-warning-normal-bg-active: var(--color-error-4, #B7321E);
    --btn-warning-normal-color-disabled: var(--color-text1-1, #CCCCCC);
    --btn-warning-normal-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --btn-warning-normal-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --btn-text-primary-color: var(--color-link-1, #298DFF);
    --btn-text-primary-color-hover: var(--color-brand1-9, #1274E7);
    --btn-text-disabled-color: var(--color-text1-1, #CCCCCC);
    --btn-text-loading-color: var(--color-text1-4, #333333);
    --btn-text-secondary-color: var(--color-text1-3, #666666);
    --btn-text-secondary-color-hover: var(--color-brand1-6, #209BFA);
    --btn-text-normal-color: var(--color-text1-4, #333333);
    --btn-text-normal-color-hover: var(--color-brand1-6, #209BFA);
    --btn-text-size-s-height: var(--s-4, 16px);
    --btn-text-size-s-font: var(--font-size-caption, 12px);
    --btn-text-icon-size-s: var(--icon-s, 16px);
    --btn-text-icon-s-margin: var(--s-1, 4px);
    --btn-text-size-m-height: var(--s-5, 20px);
    --btn-text-size-m-font: var(--font-size-body-1, 14px);
    --btn-text-icon-size-m: var(--icon-m, 20px);
    --btn-text-icon-m-margin: var(--s-1, 4px);
    --btn-text-size-l-height: var(--s-6, 24px);
    --btn-text-size-l-font: var(--font-size-body-2, 14px);
    --btn-text-icon-size-l: var(--icon-l, 24px);
    --btn-text-icon-l-margin: var(--s-1, 4px);
    /* ------------------------ tag ------------------------ */
    --tag-size-m-height: var(--s-8, 32px);
    --tag-size-s-height: var(--s-6, 24px);
    --tag-size-l-icon-font: var(--icon-s, 16px);
    --tag-size-m-icon-font: var(--icon-xs, 12px);
    --tag-size-l-tick-icon-size: var(--icon-s, 16px);
    --tag-size-l-height: var(--s-10, 40px);
    --tag-size-l-spacing: var(--s-4, 16px);
    --tag-size-m-spacing: var(--s-3, 12px);
    --tag-size-s-spacing: var(--s-2, 8px);
    --tag-size-l-padding-lr: var(--s-4, 16px);
    --tag-size-m-padding-lr: var(--s-3, 12px);
    --tag-size-s-padding-lr: var(--s-2, 8px);
    --tag-corner-radius: var(--corner-1, 3px);
    --tag-checkable-corner-radius: var(--corner-1, 3px);
    --tag-border-width: var(--line-1, 1px);
    --tag-shadow: var(--shadow-zero, none);
    --tag-size-l-text-size: var(--font-size-subhead, 16px);
    --tag-size-m-text-size: var(--font-size-body-2, 14px);
    --tag-size-s-text-size: var(--font-size-caption, 12px);
    --tag-size-s-content-min-width: var(--s-7, 28px);
    --tag-size-m-content-min-width: var(--s-10, 40px);
    --tag-size-l-content-min-width: var(--s-12, 48px);
    --tag-size-s-icon-font: var(--icon-xxs, 8px);
    --tag-size-m-tick-icon-size: var(--icon-xs, 12px);
    --tag-size-s-tick-icon-size: var(--icon-xxs, 8px);
    --tag-size-l-tick-icon-bg-size: var(--s-9, 36px);
    --tag-size-m-tick-icon-bg-size: var(--s-7, 28px);
    --tag-size-s-tick-icon-bg-size: var(--s-5, 20px);
    --tag-normal-text-color: var(--color-text1-4, #333333);
    --tag-fill-text-color: var(--color-text1-3, #666666);
    --tag-closable-normal-icon-color: var(--color-white, #FFFFFF);
    --tag-closable-normal-icon-color-hover: var(--color-white, #FFFFFF);
    --tag-closable-normal-icon-color-disabled: var(--color-white, #FFFFFF);
    --tag-closable-normal-bg: var(--color-line1-3, #DDDDDD);
    --tag-closable-normal-bg-hover: var(--color-line1-4, #CCCCCC);
    --tag-closable-normal-bg-disabled: var(--color-line1-1, #EEEEEE);
    --tag-closable-bordered-text-color: var(--color-text1-3, #666666);
    --tag-closable-bordered-border-color: var(--color-line1-3, #DDDDDD);
    --tag-closable-bordered-bg: var(--color-transparent, transparent);
    --tag-closable-bordered-text-color-hover: var(--color-text1-4, #333333);
    --tag-closable-bordered-border-color-hover: var(--color-line1-4, #CCCCCC);
    --tag-closable-bordered-bg-hover: var(--color-transparent, transparent);
    --tag-closable-bordered-tail-color: var(--color-text1-3, #666666);
    --tag-closable-bordered-tail-color-hover: var(--color-text1-4, #333333);
    --tag-closable-bordered-tail-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-closable-bordered-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --tag-closable-bordered-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-closable-bordered-bg-disabled: var(--color-transparent, transparent);
    --tag-fill-tail-color: var(--color-text1-3, #666666);
    --tag-fill-border-color: var(--color-fill1-3, #F5F5F5);
    --tag-fill-border-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-fill-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-fill-border-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-fill-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-fill-tail-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-bordered-text-color: var(--color-text1-3, #666666);
    --tag-bordered-tail-color: var(--color-text1-3, #666666);
    --tag-secondary-text-color: var(--color-brand1-6, #209BFA);
    --tag-normal-text-color-hover: var(--color-brand1-6, #209BFA);
    --tag-fill-text-color-hover: var(--color-text1-4, #333333);
    --tag-fill-tail-color-hover: var(--color-text1-4, #333333);
    --tag-bordered-text-color-hover: var(--color-text1-4, #333333);
    --tag-bordered-tail-color-hover: var(--color-text1-4, #333333);
    --tag-bordered-tail-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-bordered-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-bordered-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --tag-bordered-bg-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-secondary-text-color-hover: var(--color-brand1-9, #1274E7);
    --tag-bordered-border-color: var(--color-line1-3, #DDDDDD);
    --tag-secondary-border-color: var(--color-brand1-6, #209BFA);
    --tag-checkable-normal-bg-selected: var(--color-brand1-6, #209BFA);
    --tag-checkable-secondary-bg-selected: var(--color-brand1-6, #209BFA);
    --tag-bordered-border-color-hover: var(--color-line1-4, #CCCCCC);
    --tag-secondary-border-color-hover: var(--color-brand1-9, #1274E7);
    --tag-checkable-normal-bg-selected-hover: var(--color-brand1-9, #1274E7);
    --tag-checkable-secondary-bg-selected-hover: var(--color-brand1-9, #1274E7);
    --tag-fill-bg-color: var(--color-fill1-3, #F5F5F5);
    --tag-primary-background-color: var(--color-brand1-6, #209BFA);
    --tag-bordered-bg: var(--color-transparent, transparent);
    --tag-secondary-bg: var(--color-transparent, transparent);
    --tag-fill-bg-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-bordered-bg-hover: var(--color-transparent, transparent);
    --tag-secondary-bg-hover: var(--color-transparent, transparent);
    --tag-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --tag-checkable-fill-text-color: var(--color-text1-3, #666666);
    --tag-checkable-fill-bg-color: var(--color-fill1-3, #F5F5F5);
    --tag-checkable-fill-border-color: var(--color-fill1-3, #F5F5F5);
    --tag-checkable-fill-text-color-hover: var(--color-text1-4, #333333);
    --tag-checkable-fill-bg-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-checkable-fill-border-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-checkable-fill-tail-color: var(--color-text1-3, #666666);
    --tag-checkable-fill-tail-color-hover: var(--color-text1-4, #333333);
    --tag-checkable-fill-tail-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-checkable-fill-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-checkable-fill-border-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-checkable-fill-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-checkable-normal-text-color: var(--color-text1-3, #666666);
    --tag-checkable-normal-border-color: var(--color-line1-3, #DDDDDD);
    --tag-checkable-normal-bg: var(--color-transparent, transparent);
    --tag-checkable-normal-text-color-hover: var(--color-text1-4, #333333);
    --tag-checkable-normal-border-color-hover: var(--color-line1-3, #DDDDDD);
    --tag-checkable-normal-bg-hover: var(--color-transparent, transparent);
    --tag-checkable-normal-text-selected-disabled: var(--color-text1-1, #CCCCCC);
    --tag-checkable-normal-border-selected-disabled: var(--color-line1-1, #EEEEEE);
    --tag-checkable-normal-bg-selected-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-checkable-normal-tick-bg-selected-disabled: var(--color-line1-1, #EEEEEE);
    --tag-checkable-primary-text-color: var(--color-white, #FFFFFF);
    --tag-checkable-primary-bg-color: var(--color-brand1-6, #209BFA);
    --tag-checkable-primary-border-color: var(--color-brand1-6, #209BFA);
    --tag-checkable-primary-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-checkable-primary-text-color-hover: var(--color-white, #FFFFFF);
    --tag-checkable-primary-bg-color-hover: var(--color-brand1-9, #1274E7);
    --tag-checkable-primary-border-color-hover: var(--color-brand1-9, #1274E7);
    --tag-checkable-primary-border-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-checkable-primary-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-checkable-primary-tail-color: var(--color-white, #FFFFFF);
    --tag-checkable-primary-tail-color-hover: var(--color-white, #FFFFFF);
    --tag-checkable-secondary-bg-selected-disabled: var(--color-line1-1, #EEEEEE);
    --tag-checkable-normal-icon-color-selected: var(--color-white, #FFFFFF);
    --tag-checkable-normal-icon-color-selected-hover: var(--color-white, #FFFFFF);
    --tag-checkable-normal-icon-color-selected-disabled: var(--color-white, #FFFFFF);
    --tag-checkable-secondary-icon-color-selected: var(--color-white, #FFFFFF);
    --tag-checkable-secondary-icon-color-selected-hover: var(--color-white, #FFFFFF);
    --tag-checkable-secondary-icon-color-selected-disabled: var(--color-white, #FFFFFF);
    --tag-closable-primary-fill-text-color: var(--color-text1-3, #666666);
    --tag-closable-primary-fill-border-color: var(--color-fill1-3, #F5F5F5);
    --tag-closable-primary-fill-bg-color: var(--color-fill1-3, #F5F5F5);
    --tag-closable-primary-fill-text-color-hover: var(--color-text1-4, #333333);
    --tag-closable-primary-fill-border-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-closable-primary-fill-bg-color-hover: var(--color-fill1-4, #F2F2F2);
    --tag-closable-primary-fill-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tag-closable-primary-fill-border-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tag-closable-primary-fill-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    /* ------------------------ input ------------------------ */
    --input-placeholder-color: var(--color-text1-1, #CCCCCC);
    --input-text-color: var(--color-text1-4, #333333);
    --input-border-color: var(--color-line1-3, #DDDDDD);
    --input-label-color: var(--color-text1-3, #666666);
    --input-hover-border-color: var(--color-line1-4, #CCCCCC);
    --input-hover-bg-color: var(--color-white, #FFFFFF);
    --input-focus-border-color: var(--color-brand1-6, #209BFA);
    --input-focus-bg-color: var(--color-white, #FFFFFF);
    --input-focus-shadow-spread: var(--line-2, 2px);
    --input-border-width: var(--line-1, 1px);
    --input-bg-color: var(--color-white, #FFFFFF);
    --input-disabled-color: var(--color-text1-1, #CCCCCC);
    --input-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --input-disabled-bg-color: var(--color-fill1-1, #FAFAFA);
    --input-multiple-padding-lr: var(--s-2, 8px);
    --input-multiple-padding-tb: var(--s-1, 4px);
    --input-multiple-font-size: var(--font-size-body-1, 14px);
    --input-multiple-corner: var(--corner-1, 3px);
    --input-addon-bg-color: var(--color-fill1-2, #F9F9F9);
    --input-addon-text-color: var(--color-text1-2, #999999);
    --input-addon-padding: var(--s-2, 8px);
    --input-l-padding: var(--s-3, 12px);
    --input-l-label-padding-left: var(--s-3, 12px);
    --input-l-icon-padding-right: var(--s-2, 8px);
    --input-m-padding: var(--s-2, 8px);
    --input-m-label-padding-left: var(--s-2, 8px);
    --input-m-icon-padding-right: var(--s-2, 8px);
    --input-s-padding: var(--s-1, 4px);
    --input-s-label-padding-left: var(--s-2, 8px);
    --input-s-icon-padding-right: var(--s-1, 4px);
    --input-feedback-warning-border-color: var(--color-warning-3, #F1C826);
    --input-feedback-warning-bg-color: var(--color-white, #FFFFFF);
    --input-feedback-warning-color: var(--color-warning-3, #F1C826);
    --input-feedback-warning-icon: var(--icon-content-warning, "");
    --input-feedback-success-color: var(--color-success-3, #1AD78C);
    --input-feedback-success-icon: var(--icon-content-success-filling, "");
    --input-feedback-loading-color: var(--color-notice-3, #298DFF);
    --input-feedback-loading-icon: var(--icon-content-loading, "");
    --input-feedback-error-color: var(--input-text-color, #333333);
    --input-feedback-error-border-color: var(--color-error-3, #D23C26);
    --input-feedback-error-bg-color: var(--color-white, #FFFFFF);
    --input-maxlen-error-color: var(--color-error-3, #D23C26);
    --input-maxlen-warning-color: var(--color-warning-3, #F1C826);
    --input-maxlen-color: var(--color-text1-2, #999999);
    --input-maxlen-font-size: var(--font-size-caption, 12px);
    --input-hint-color: var(--color-text1-2, #999999);
    --input-hint-hover-color: var(--color-text1-3, #666666);
    --input-feedback-clear-icon: var(--icon-content-delete-filling, "");
    /* ------------------------ table ------------------------ */
    --table-row-hover-bg: var(--color-fill1-1, #FAFAFA);
    --table-normal-border-width: var(--line-1, 1px);
    --table-th-font-size: var(--font-size-body-1, 14px);
    --table-th-font-weight: var(--font-weight-2, normal);
    --table-sort-icon-size: var(--icon-xs, 12px);
    --table-filter-icon-size: var(--icon-xs, 12px);
    --table-body-font-size: var(--font-size-body-1, 14px);
    --table-empty-padding: var(--s-8, 32px);
    --table-expanded-icon-size: var(--icon-xs, 12px);
    --table-tree-expanded-icon-size: var(--icon-xs, 12px);
    --table-cell-padding-top: var(--s-3, 12px);
    --table-cell-padding-left: var(--s-4, 16px);
    --table-header-padding-top: var(--s-3, 12px);
    --table-header-padding-left: var(--s-4, 16px);
    --table-header-icon-margin-left: var(--s-2, 8px);
    --table-header-corner-top: var(--corner-zero, 0px);
    --table-header-corner-bottom: var(--corner-zero, 0px);
    --table-size-s-cell-padding-top: var(--s-2, 8px);
    --table-size-s-cell-padding-left: var(--s-2, 8px);
    --table-size-s-header-padding-top: var(--s-2, 8px);
    --table-size-s-header-padding-left: var(--s-2, 8px);
    --table-group-split: var(--s-2, 8px);
    --table-group-header-corner-top: var(--corner-zero, 0px);
    --table-group-header-corner-bottom: var(--corner-zero, 0px);
    --table-group-footer-corner-top: var(--corner-zero, 0px);
    --table-group-footer-corner-bottom: var(--corner-zero, 0px);
    --table-normal-border-color: var(--color-line1-2, #E6E6E6);
    --table-normal-border-style: var(--line-solid, solid);
    --table-th-bg: var(--color-fill1-3, #F5F5F5);
    --table-th-color: var(--color-text1-4, #333333);
    --table-sort-color: var(--color-text1-4, #333333);
    --table-group-th-bg: var(--color-fill1-3, #F5F5F5);
    --table-group-th-color: var(--color-text1-4, #333333);
    --table-row-bg: var(--color-white, #FFFFFF);
    --table-row-color: var(--color-text1-4, #333333);
    --table-td-gray: var(--color-fill1-1, #FAFAFA);
    --table-td-normal: var(--color-white, #FFFFFF);
    --table-empty-color: var(--color-line1-4, #CCCCCC);
    --table-group-footer-bg: var(--color-fill1-3, #F5F5F5);
    --table-group-footer-color: var(--color-text1-4, #333333);
    --table-row-hover-color: var(--color-text1-4, #333333);
    --table-row-selected-bg: var(--color-fill1-2, #F9F9F9);
    --table-row-selected-color: var(--color-text1-4, #333333);
    --table-sort-color-current: var(--color-brand1-6, #209BFA);
    --table-expanded-ctrl-disabled-color: var(--color-text1-2, #999999);
    --table-tree-fold-icon-content: var(--icon-content-arrow-right, "");
    --table-tree-unfold-icon-content: var(--icon-content-arrow-down, "");
    --table-expand-fold-icon-content: var(--icon-content-add, "");
    --table-expand-unfold-icon-content: var(--icon-content-minus, "");
    /* ------------------------ balloon ------------------------ */
    --balloon-primary-color-bg: var(--color-brand1-1, #ADD9FF);
    --balloon-primary-color-border: var(--color-brand1-6, #209BFA);
    --balloon-tooltip-color-bg: var(--color-text1-4, #333333);
    --balloon-tooltip-color-border: var(--color-transparent, transparent);
    --balloon-tooltip-color: var(--color-fill1-1, #FAFAFA);
    --balloon-size-close: var(--icon-s, 16px);
    --balloon-size-max-width: 300px;
    --balloon-size-padding-right: var(--s-4, 16px);
    --balloon-size-padding-closable-right: var(--s-10, 40px);
    --balloon-size-padding-top: var(--s-4, 16px);
    --balloon-size-header-margin-bottom: var(--s-2, 8px);
    --balloon-title-font-size: var(--font-size-subhead, 16px);
    --balloon-title-font-weight: var(--font-weight-3, bold);
    --balloon-content-font-size: var(--font-size-body-1, 14px);
    --balloon-content-font-weight: var(--font-weight-2, normal);
    --balloon-normal-border-width: var(--line-1, 1px);
    --balloon-primary-border-width: var(--line-1, 1px);
    --balloon-tooltip-border-width: var(--line-1, 1px);
    --balloon-tooltip-content-font-size: var(--font-size-body-1, 14px);
    --balloon-tooltip-content-font-weight: var(--font-weight-2, normal);
    --balloon-size-close-margin-top: var(--s-3, 12px);
    --balloon-size-close-margin-right: var(--s-3, 12px);
    --balloon-size-arrow-size: var(--s-3, 12px);
    --balloon-size-arrow-margin: var(--s-3, 12px);
    --balloon-tooltip-size-padding-top: var(--s-2, 8px);
    --balloon-tooltip-size-padding-right: var(--s-2, 8px);
    --balloon-tooltip-size-padding-bottom: var(--s-2, 8px);
    --balloon-tooltip-size-padding-left: var(--s-2, 8px);
    --balloon-border-style: var(--line-solid, solid);
    --balloon-corner: var(--corner-1, 3px);
    --balloon-normal-color-bg: var(--color-white, #FFFFFF);
    --balloon-normal-color-border: var(--color-line1-2, #E6E6E6);
    --balloon-normal-shadow: var(--shadow-2-down, 0px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --balloon-normal-color: var(--color-text1-4, #333333);
    --balloon-normal-color-close: var(--color-text1-2, #999999);
    --balloon-normal-color-close-hover: var(--color-text1-3, #666666);
    --balloon-primary-shadow: var(--shadow-1-down, 0px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --balloon-primary-color: var(--color-text1-4, #333333);
    --balloon-primary-color-close: var(--color-text1-2, #999999);
    --balloon-primary-color-close-hover: var(--color-text1-4, #333333);
    --balloon-tooltip-shadow: var(--shadow-zero, none);
    --balloon-tooltip-border-style: var(--line-solid, solid);
    /* ------------------------ dialog ------------------------ */
    --dialog-border-width: var(--line-1, 1px);
    --dialog-corner: var(--corner-2, 6px);
    --dialog-close-size: var(--icon-s, 16px);
    --dialog-title-font-size: var(--font-size-subhead, 16px);
    --dialog-title-font-weight: var(--font-weight-2, normal);
    --dialog-title-padding-top: var(--s-3, 12px);
    --dialog-title-padding-bottom: var(--s-3, 12px);
    --dialog-title-padding-left-right: var(--s-5, 20px);
    --dialog-title-border-width: var(--line-zero, 0px);
    --dialog-message-content-padding-top: var(--s-5, 20px);
    --dialog-message-content-padding-bottom: var(--s-5, 20px);
    --dialog-message-content-padding-left-right: var(--s-5, 20px);
    --dialog-content-font-size: var(--font-size-body-1, 14px);
    --dialog-content-padding-top: var(--s-5, 20px);
    --dialog-content-padding-bottom: var(--s-5, 20px);
    --dialog-content-padding-left-right: var(--s-5, 20px);
    --dialog-content-margin-bottom-no-footer: var(--s-0, 0px);
    --dialog-footer-border-width: var(--line-zero, 0px);
    --dialog-footer-padding-top: var(--s-3, 12px);
    --dialog-footer-padding-bottom: var(--s-3, 12px);
    --dialog-footer-padding-left-right: var(--s-5, 20px);
    --dialog-footer-button-spacing: var(--s-1, 4px);
    --dialog-close-top: var(--s-3, 12px);
    --dialog-close-right: var(--s-3, 12px);
    --dialog-close-width: var(--s-4, 16px);
    --dialog-bg: var(--color-white, #FFFFFF);
    --dialog-border-color: var(--color-line1-2, #E6E6E6);
    --dialog-border-style: var(--line-solid, solid);
    --dialog-shadow: var(--shadow-2-down, 0px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --dialog-title-color: var(--color-text1-4, #333333);
    --dialog-title-bg-color: var(--color-transparent, transparent);
    --dialog-title-border-color: var(--color-transparent, transparent);
    --dialog-content-color: var(--color-text1-3, #666666);
    --dialog-footer-bg-color: var(--color-transparent, transparent);
    --dialog-footer-border-color: var(--color-transparent, transparent);
    --dialog-close-color: var(--color-text1-2, #999999);
    --dialog-close-color-hovered: var(--color-text1-4, #333333);
    --dialog-close-bg-hovered: var(--color-transparent, transparent);
    /* ------------------------ progress ------------------------ */
    --progress-circle-size-l: var(--s-33, 132px);
    --progress-line-height-size-l: var(--s-3, 12px);
    --progress-line-height-size-m: var(--s-2, 8px);
    --progress-line-height-size-s: var(--s-1, 4px);
    --progress-line-underlay-border-width: var(--line-1, 1px);
    --progress-line-font-l: var(--font-size-body-1, 14px);
    --progress-line-font-m: var(--font-size-caption, 12px);
    --progress-line-font-s: var(--font-size-caption, 12px);
    --progress-line-underlay-color: var(--color-fill1-3, #F5F5F5);
    --progress-line-underlay-border-color: var(--color-line1-2, #E6E6E6);
    --progress-line-radius-l: var(--corner-3, 12px);
    --progress-line-radius-m: var(--corner-3, 12px);
    --progress-line-radius-s: var(--corner-3, 12px);
    --progress-line-font-color: var(--color-text1-4, #333333);
    --progress-line-normal-color: var(--color-brand1-6, #209BFA);
    --progress-line-error-color: var(--color-error-3, #D23C26);
    --progress-line-success-color: var(--color-success-3, #1AD78C);
    --progress-line-started-color: var(--color-error-3, #D23C26);
    --progress-line-middle-color: var(--color-warning-3, #F1C826);
    --progress-line-finishing-color: var(--color-success-3, #1AD78C);
    --progress-circle-size-m: var(--s-29, 116px);
    --progress-circle-size-s: var(--s-25, 100px);
    --progress-circle-underlay-width: var(--s-2, 8px);
    --progress-circle-overlay-width: var(--s-2, 8px);
    --progress-circle-font-l: var(--font-size-display-1, 36px);
    --progress-circle-font-m: var(--font-size-headline, 24px);
    --progress-circle-font-s: var(--font-size-title, 20px);
    --progress-circle-underlay-color: var(--color-fill1-3, #F5F5F5);
    --progress-circle-text-color: var(--color-text1-4, #333333);
    --progress-circle-corner: round;
    --progress-circle-normal-color: var(--color-brand1-6, #209BFA);
    --progress-circle-error-color: var(--color-error-3, #D23C26);
    --progress-circle-success-color: var(--color-success-3, #1AD78C);
    --progress-circle-started-color: var(--color-error-3, #D23C26);
    --progress-circle-middle-color: var(--color-warning-3, #F1C826);
    --progress-circle-finishing-color: var(--color-success-3, #1AD78C);
    /* ------------------------ radio ------------------------ */
    --radio-button-height-medium: var(--s-8, 32px);
    --radio-width: var(--s-4, 16px);
    --radio-circle-border-width: var(--line-1, 1px);
    --radio-circle-size: var(--s-1, 4px);
    --radio-font-margin-left: var(--s-1, 4px);
    --radio-font-size: var(--font-size-body-1, 14px);
    --radio-shadow: var(--shadow-zero, none);
    --radio-radius-size: var(--corner-circle, 50%);
    --radio-bg-color: var(--color-white, #FFFFFF);
    --radio-hovered-bg-color: var(--color-brand1-1, #ADD9FF);
    --radio-checked-bg-color: var(--color-brand1-6, #209BFA);
    --radio-disabled-bg-color: var(--color-fill1-1, #FAFAFA);
    --radio-checked-hovered-bg-color: var(--color-brand1-9, #1274E7);
    --radio-checked-disabled-bg-color: var(--color-fill1-1, #FAFAFA);
    --radio-border-color: var(--color-line1-3, #DDDDDD);
    --radio-hovered-border-color: var(--color-brand1-6, #209BFA);
    --radio-checked-border-color: var(--color-brand1-6, #209BFA);
    --radio-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --radio-checked-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --radio-checked-hovered-border-color: var(--color-transparent, transparent);
    --radio-checked-circle-color: var(--color-white, #FFFFFF);
    --radio-disabled-circle-color: var(--color-text1-1, #CCCCCC);
    --radio-checked-hovered-circle-color: var(--color-white, #FFFFFF);
    --radio-checked-disabled-circle-color: var(--color-text1-1, #CCCCCC);
    --radio-normal-font-color: var(--color-text1-4, #333333);
    --radio-normal-font-color-disabled: var(--color-text1-1, #CCCCCC);
    --radio-button-height-large: var(--s-10, 40px);
    --radio-button-height-small: var(--s-5, 20px);
    --radio-button-padding-large: var(--s-2, 8px);
    --radio-button-padding-medium: var(--s-2, 8px);
    --radio-button-padding-small: var(--s-2, 8px);
    --radio-button-corner-large: var(--corner-1, 3px);
    --radio-button-corner-medium: var(--corner-1, 3px);
    --radio-button-corner-small: var(--corner-1, 3px);
    --radio-button-font-size-large: var(--font-size-subhead, 16px);
    --radio-button-font-size-medium: var(--font-size-body-1, 14px);
    --radio-button-font-size-small: var(--font-size-caption, 12px);
    --radio-button-bg-color: var(--color-white, #FFFFFF);
    --radio-button-bg-color-hovered: var(--color-fill1-2, #F9F9F9);
    --radio-button-bg-color-checked: var(--color-white, #FFFFFF);
    --radio-button-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --radio-button-bg-color-checked-disabled: var(--color-fill1-2, #F9F9F9);
    --radio-button-border-color: var(--color-line1-3, #DDDDDD);
    --radio-button-border-color-hovered: var(--color-line1-4, #CCCCCC);
    --radio-button-border-color-checked: var(--color-brand1-6, #209BFA);
    --radio-button-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --radio-button-border-color-checked-disabled: var(--color-line1-1, #EEEEEE);
    --radio-button-font-color: var(--color-text1-4, #333333);
    --radio-button-font-color-hovered: var(--color-text1-4, #333333);
    --radio-button-font-color-checked: var(--color-brand1-6, #209BFA);
    --radio-button-font-color-disabled: var(--color-text1-1, #CCCCCC);
    --radio-button-font-color-checked-disabled: var(--color-text1-1, #CCCCCC);
    /* ------------------------ tab ------------------------ */
    --tab-nav-tab-icon-size-m: var(--icon-s, 16px);
    --tab-item-padding-tb-size-m: var(--s-5, 20px);
    --tab-nav-scroll-padding-right-m: var(--s-15, 60px);
    --tab-nav-scroll-padding-right-s: var(--s-14, 56px);
    --tab-nav-tab-icon-size-s: var(--icon-xs, 12px);
    --tab-nav-close-icon-size-m: var(--icon-xs, 12px);
    --tab-nav-close-icon-size-s: var(--icon-xxs, 8px);
    --tab-nav-close-icon-padding-l-size-m: var(--s-2, 8px);
    --tab-nav-close-icon-padding-l-size-s: var(--s-2, 8px);
    --tab-nav-arrow-left-positon-right: var(--s-8, 32px);
    --tab-nav-arrow-right-positon-right: var(--s-2, 8px);
    --tab-nav-arrow-down-positon-right: var(--s-2, 8px);
    --tab-item-padding-tb-size-s: var(--s-2, 8px);
    --tab-item-padding-lr-size-m: var(--s-4, 16px);
    --tab-item-padding-lr-size-s: var(--s-3, 12px);
    --tab-item-text-size-m: var(--font-size-body-1, 14px);
    --tab-item-text-size-s: var(--font-size-caption, 12px);
    --tab-close-icon-color: var(--color-text1-3, #666666);
    --tab-close-icon-color-hover: var(--color-text1-4, #333333);
    --tab-close-icon-color-selected: var(--color-brand1-6, #209BFA);
    --tab-close-icon-color-disabled: var(--color-line1-2, #E6E6E6);
    --tab-item-font-weight-selected: var(--font-weight-2, normal);
    --tab-nav-arrow-color-normal: var(--color-text1-3, #666666);
    --tab-nav-arrow-color-hover: var(--color-text1-4, #333333);
    --tab-nav-arrow-color-disabled: var(--color-line1-2, #E6E6E6);
    --tab-pure-divider-line-width: var(--line-1, 1px);
    --tab-pure-ink-bar-width: var(--line-2, 2px);
    --tab-pure-ink-bar-padding-l: var(--s-zero, 0px);
    --tab-pure-text-color-normal: var(--color-text1-3, #666666);
    --tab-pure-bg-color: var(--color-transparent, transparent);
    --tab-pure-text-color-hover: var(--color-text1-4, #333333);
    --tab-pure-text-color-selected: var(--color-brand1-6, #209BFA);
    --tab-pure-text-color-disabled: var(--color-line1-2, #E6E6E6);
    --tab-pure-ink-bar-color: var(--color-brand1-6, #209BFA);
    --tab-pure-divider-border-color: var(--color-line1-2, #E6E6E6);
    --tab-pure-divider-shadow: var(--shadow-zero, none);
    --tab-wrapped-border-line-style: var(--line-solid, solid);
    --tab-wrapped-border-line-width: var(--line-1, 1px);
    --tab-wrapped-border-side-width: var(--line-1, 1px);
    --tab-wrapped-tab-corner-radius: var(--corner-1, 3px);
    --tab-wrapped-tab-margin-right: var(--s-1, 4px);
    --tab-wrapped-tab-margin-bottom: var(--s-1, 4px);
    --tab-wrapped-ink-bar-width: var(--line-2, 2px);
    --tab-wrapped-ink-bar-padding-l: var(--s-zero, 0px);
    --tab-wrapped-bar-bg-color: var(--color-transparent, transparent);
    --tab-wrapped-ink-bar-color: var(--color-brand1-6, #209BFA);
    --tab-wrapped-nav-item-border-color-active: var(--color-brand1-6, #209BFA);
    --tab-wrapped-border-line-color: var(--color-line1-2, #E6E6E6);
    --tab-wrapped-content-border-line-width: var(--line-1, 1px);
    --tab-wrapped-content-border-line-style: var(--line-solid, solid);
    --tab-wrapped-content-border-line-color: var(--color-line1-2, #E6E6E6);
    --tab-wrapped-text-color: var(--color-text1-3, #666666);
    --tab-wrapped-text-color-hover: var(--color-text1-4, #333333);
    --tab-wrapped-bg-color: var(--color-fill1-2, #F9F9F9);
    --tab-wrapped-bg-color-hover: var(--color-fill1-3, #F5F5F5);
    --tab-wrapped-border-line-color-hover: var(--color-line1-3, #DDDDDD);
    --tab-wrapped-text-color-selected: var(--color-brand1-6, #209BFA);
    --tab-wrapped-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tab-wrapped-bg-color-selected: var(--color-white, #FFFFFF);
    --tab-wrapped-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tab-capsule-text-color: var(--color-text1-4, #333333);
    --tab-capsule-text-color-hover: var(--color-text1-4, #333333);
    --tab-capsule-text-color-selected: var(--color-white, #FFFFFF);
    --tab-capsule-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tab-capsule-bg-color: var(--color-fill1-2, #F9F9F9);
    --tab-capsule-bg-color-hover: var(--color-fill1-3, #F5F5F5);
    --tab-capsule-bg-color-selected: var(--color-brand1-6, #209BFA);
    --tab-capsule-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --tab-capsule-tab-border-line-color: var(--color-line1-3, #DDDDDD);
    --tab-capsule-tab-border-line-color-hover: var(--color-line1-3, #DDDDDD);
    --tab-capsule-tab-border-line-color-active: var(--color-brand1-6, #209BFA);
    --tab-capsule-tab-border-line-color-disabled: var(--color-line1-1, #EEEEEE);
    --tab-capsule-corner-radius: var(--corner-1, 3px);
    --tab-capsule-tab-border-line-width: var(--line-1, 1px);
    --tab-capsule-tab-border-line-style: var(--line-solid, solid);
    --tab-text-item-sep-width: var(--line-1, 1px);
    --tab-text-item-sep-height: var(--s-2, 8px);
    --tab-text-item-sep-color: var(--color-line1-2, #E6E6E6);
    --tab-text-text-color-normal: var(--color-text1-3, #666666);
    --tab-text-text-color-hover: var(--color-text1-4, #333333);
    --tab-text-text-color-selected: var(--color-brand1-6, #209BFA);
    --tab-text-text-color-disabled: var(--color-text1-1, #CCCCCC);
    --tab-icon-dropdown-content: var(--icon-content-arrow-down, "");
    --tab-icon-prev-content: var(--icon-content-arrow-left, "");
    --tab-icon-next-content: var(--icon-content-arrow-right, "");
    /* ------------------------ pagination ------------------------ */
    --pagination-item-current-hover-border-color: var(--color-brand1-6, #209BFA);
    --pagination-item-current-hover-color: var(--color-brand1-6, #209BFA);
    --pagination-item-current-hover-bg: var(--color-white, #FFFFFF);
    --pagination-current-color: var(--color-brand1-6, #209BFA);
    --pagination-item-split: var(--s-1, 4px);
    --pagination-item-padding: 10px;
    --pagination-item-border-style: var(--line-solid, solid);
    --pagination-item-border-width: var(--line-1, 1px);
    --pagination-item-corner: var(--corner-1, 3px);
    --pagination-ellipsis-size: var(--icon-xs, 12px);
    --pagination-ellipsis-margin: var(--s-2, 8px);
    --pagination-current-font-size: var(--font-size-body-1, 14px);
    --pagination-total-font-size: var(--font-size-body-1, 14px);
    --pagination-jump-font-size: var(--font-size-body-1, 14px);
    --pagination-input-width: var(--s-9, 36px);
    --pagination-input-margin: var(--s-1, 4px);
    --pagination-size-selector-title-margin-right: var(--s-1, 4px);
    --pagination-size-selector-number-padding: var(--s-3, 12px);
    --pagination-large-item-split: var(--s-2, 8px);
    --pagination-large-item-padding: 15px;
    --pagination-large-item-border-width: var(--line-1, 1px);
    --pagination-large-item-corner: var(--corner-1, 3px);
    --pagination-large-ellipsis-size: var(--icon-s, 16px);
    --pagination-large-current-font-size: var(--font-size-subhead, 16px);
    --pagination-large-total-font-size: var(--font-size-subhead, 16px);
    --pagination-large-input-width: var(--s-12, 48px);
    --pagination-large-jump-font-size: var(--font-size-subhead, 16px);
    --pagination-large-size-selector-number-padding: var(--s-4, 16px);
    --pagination-small-item-split: var(--s-1, 4px);
    --pagination-small-item-padding: 6px;
    --pagination-small-item-border-width: var(--line-1, 1px);
    --pagination-small-item-corner: var(--corner-1, 3px);
    --pagination-small-ellipsis-size: var(--icon-xs, 12px);
    --pagination-small-current-font-size: var(--font-size-caption, 12px);
    --pagination-small-total-font-size: var(--font-size-caption, 12px);
    --pagination-small-input-width: var(--s-7, 28px);
    --pagination-small-jump-font-size: var(--font-size-caption, 12px);
    --pagination-small-size-selector-number-padding: var(--s-2, 8px);
    --pagination-ellipsis-color: var(--color-text1-2, #999999);
    --pagination-total-color: var(--color-text1-4, #333333);
    --pagination-jump-color: var(--color-text1-2, #999999);
    --pagination-size-selector-title-color: var(--color-text1-2, #999999);
    --pagination-size-selector-filter-color: var(--color-text1-3, #666666);
    --pagination-size-selector-filter-current-color: var(--color-brand1-6, #209BFA);
    --pagination-item-border-color: var(--color-line1-3, #DDDDDD);
    --pagination-item-shadow: var(--shadow-zero, none);
    --pagination-item-color: var(--color-text1-4, #333333);
    --pagination-item-bg: var(--color-white, #FFFFFF);
    --pagination-item-current-border-color: var(--color-brand1-6, #209BFA);
    --pagination-item-current-shadow: var(--shadow-zero, none);
    --pagination-item-current-color: var(--color-white, #FFFFFF);
    --pagination-item-current-bg: var(--color-brand1-6, #209BFA);
    --pagination-item-hover-border-color: var(--color-line1-4, #CCCCCC);
    --pagination-item-hover-shadow: var(--shadow-zero, none);
    --pagination-item-hover-color: var(--color-text1-4, #333333);
    --pagination-item-hover-bg: var(--color-fill1-2, #F9F9F9);
    --pagination-arrow-color: var(--color-text1-3, #666666);
    --pagination-icon-prev-content: var(--icon-content-arrow-left, "");
    --pagination-icon-next-content: var(--icon-content-arrow-right, "");
    --pagination-icon-ellipsis-content: var(--icon-content-ellipsis, "");
    --pagination-item-current-hover-shadow: var(--shadow-zero, none);
    --pagination-arrow-hover-color: var(--color-text1-4, #333333);
    --pagination-arrow-hover-color-noboder: var(--color-brand1-6, #209BFA);
    /* ------------------------ cascader ------------------------ */
    --cascader-menu-icon-expand-size: var(--icon-m, 20px);
    --cascader-menu-border-width: var(--line-1, 1px);
    --cascader-menu-width: var(--s-auto, auto);
    --cascader-menu-min-width: var(--s-25, 100px);
    --cascader-menu-height: var(--s-48, 192px);
    --cascader-menu-border-color: var(--color-line1-2, #E6E6E6);
    --cascader-menu-border-radius: var(--corner-1, 3px);
    --cascader-menu-icon-expand-color: var(--color-text1-3, #666666);
    --cascader-menu-icon-hover-expand-color: var(--color-text1-4, #333333);
    --cascader-menu-item-expanded-color: var(--color-text1-4, #333333);
    --cascader-menu-item-expanded-background-color: var(--color-fill1-2, #F9F9F9);
    /* ------------------------ tree ------------------------ */
    --tree-switch-arrow-size: var(--icon-m, 20px);
    --tree-node-padding: var(--s-1, 4px);
    --tree-node-title-margin: var(--s-1, 4px);
    --tree-node-title-padding: var(--s-1, 4px);
    --tree-node-title-font-size: var(--font-size-body-1, 14px);
    --tree-switch-size: var(--s-4, 16px);
    --tree-switch-icon-size: var(--icon-xxs, 8px);
    --tree-switch-border-width: var(--line-1, 1px);
    --tree-switch-margint-right: var(--s-2, 8px);
    --tree-line-width: var(--line-1, 1px);
    --tree-node-normal-color: var(--color-text1-4, #333333);
    --tree-node-normal-background: var(--color-white, #FFFFFF);
    --tree-node-title-border-radius: var(--corner-1, 3px);
    --tree-switch-arrow-color: var(--color-text1-2, #999999);
    --tree-switcher-fold-icon-content: var(--icon-content-add, "");
    --tree-switcher-unfold-icon-content: var(--icon-content-minus, "");
    --tree-switch-icon-color: var(--color-text1-3, #666666);
    --tree-switch-bg-color: var(--color-white, #FFFFFF);
    --tree-switch-border-color: var(--color-line1-3, #DDDDDD);
    --tree-switch-corner: var(--corner-1, 3px);
    --tree-line-color: var(--color-line1-3, #DDDDDD);
    --tree-line-style: var(--line-solid, solid);
    --tree-node-hover-color: var(--color-text1-4, #333333);
    --tree-node-hover-bg-color: var(--color-fill1-2, #F9F9F9);
    --tree-switch-hover-arrow-color: var(--color-text1-4, #333333);
    --tree-switch-hover-icon-color: var(--color-text1-4, #333333);
    --tree-switch-hover-bg-color: var(--color-fill1-2, #F9F9F9);
    --tree-switch-hover-border-color: var(--color-line1-4, #CCCCCC);
    --tree-node-selected-color: var(--color-text1-4, #333333);
    --tree-node-selected-background: var(--color-brand1-1, #ADD9FF);
    --tree-node-disabled-color: var(--color-text1-1, #CCCCCC);
    --tree-node-disabled-background: var(--color-white, #FFFFFF);
    --tree-fold-icon-content: var(--icon-content-arrow-down, "");
    --tree-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    /* ------------------------ menu ------------------------ */
    --menu-icon-selected-size: var(--icon-s, 16px);
    --menu-icon-size: var(--icon-m, 20px);
    --menu-padding-ver-padding-lr: var(--s-zero, 0px);
    --menu-padding-ver-padding-tb: var(--s-2, 8px);
    --menu-padding-hoz-padding-lr: var(--s-zero, 0px);
    --menu-padding-hoz-padding-tb: var(--s-2, 8px);
    --menu-item-padding-ver-padding-l: var(--s-5, 20px);
    --menu-item-padding-ver-padding-r: var(--s-5, 20px);
    --menu-item-padding-hoz-padding-lr: var(--s-5, 20px);
    --menu-line-height: var(--s-8, 32px);
    --menu-font-size: var(--font-size-body-1, 14px);
    --menu-submenu-title-size: var(--font-size-body-1, 14px);
    --menu-padding-title-horizontal: var(--s-3, 12px);
    --menu-divider-width: var(--line-1, 1px);
    --menu-divider-margin-ver: var(--s-2, 8px);
    --menu-divider-margin-hoz: var(--s-3, 12px);
    --menu-icon-selected-right: var(--s-1, 4px);
    --menu-hoz-icon-size: var(--icon-xs, 12px);
    --menu-icon-margin: var(--s-1, 4px);
    --menu-shadow: var(--shadow-1-down, 0px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --menu-color: var(--color-text1-4, #333333);
    --menu-divider-style: var(--line-solid, solid);
    --menu-divider-color: var(--color-line1-1, #EEEEEE);
    --menu-background: var(--color-white, #FFFFFF);
    --menu-arrow-color: var(--color-text1-3, #666666);
    --menu-background-selected: var(--color-white, #FFFFFF);
    --menu-color-selected: var(--color-text1-4, #333333);
    --menu-icon-selected-color: var(--color-brand1-6, #209BFA);
    --menu-background-hover: var(--color-fill1-2, #F9F9F9);
    --menu-color-hover: var(--color-text1-4, #333333);
    --menu-arrow-color-hover: var(--color-text1-4, #333333);
    --menu-arrow-right: 10px;
    --menu-icon-selected-hover-color: var(--color-brand1-6, #209BFA);
    --menu-color-disabled: var(--color-text1-1, #CCCCCC);
    --menu-select-icon-content: var(--icon-content-select, "");
    --menu-fold-icon-content: var(--icon-content-arrow-down, "");
    --menu-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    --menu-popupfold-icon-content: var(--icon-content-arrow-right, "");
    /* ------------------------ nav ------------------------ */
    --nav-icon-self-size: var(--icon-m, 20px);
    --nav-primary-item-hover-bg-color: var(--color-text1-4, #333333);
    --nav-primary-item-selected-bg-color: var(--color-text1-4, #333333);
    --nav-primary-sub-nav-selected-bg-color: var(--color-text1-4, #333333);
    --nav-icon-self-margin: var(--s-3, 12px);
    --nav-primary-sub-nav-hover-bg-color: var(--color-text1-4, #333333);
    --nav-icon-only-font-size: var(--icon-m, 20px);
    --nav-primary-text-color: var(--color-line1-3, #DDDDDD);
    --nav-primary-sub-nav-text-color: var(--color-line1-3, #DDDDDD);
    --nav-primary-sub-nav-hover-text-color: var(--color-line1-3, #DDDDDD);
    --nav-ver-item-padding-lr: var(--s-4, 16px);
    --nav-ver-sub-nav-font-size: var(--font-size-body-2, 14px);
    --nav-ver-height: var(--s-13, 52px);
    --nav-ver-item-margin-tb: var(--s-zero, 0px);
    --nav-ver-sub-nav-height: var(--s-13, 52px);
    --nav-primary-bg-color: var(--color-fill1-5, #222222);
    --nav-primary-border-color: var(--color-fill1-5, #222222);
    --nav-primary-sub-nav-bg-color: var(--color-fill1-6, #151515);
    --nav-primary-item-opened-bg-color: var(--color-fill1-5, #222222);
    --nav-normal-text-color: var(--color-text1-3, #666666);
    --nav-normal-sub-nav-bg-color: var(--color-fill1-1, #FAFAFA);
    --nav-normal-sub-nav-text-color: var(--color-text1-3, #666666);
    --nav-normal-item-hover-text-color: var(--color-text1-4, #333333);
    --nav-normal-item-hover-text-style: var(--font-weight-medium, 500);
    --nav-normal-item-hover-active-color: var(--color-notice-4, #1B84E0);
    --nav-normal-sub-nav-hover-text-color: var(--color-notice-3, #298DFF);
    --nav-normal-item-opened-text-color: var(--color-text1-4, #333333);
    --nav-normal-sub-nav-selected-bg-color: var(--color-notice-1, #E4F3FE);
    --nav-normal-item-selected-bg-color: var(--color-notice-1, #E4F3FE);
    --nav-hoz-height: var(--s-11, 44px);
    --nav-hoz-font-size: var(--font-size-body-1, 14px);
    --nav-hoz-item-margin-tb: var(--s-zero, 0px);
    --nav-hoz-item-margin-lr: var(--s-zero, 0px);
    --nav-hoz-item-padding-lr: var(--s-5, 20px);
    --nav-hoz-item-corner: var(--corner-zero, 0px);
    --nav-hoz-item-selected-active-line: var(--line-2, 2px);
    --nav-hoz-item-hover-active-line: var(--line-zero, 0px);
    --nav-ver-font-size: var(--font-size-body-1, 14px);
    --nav-ver-item-margin-lr: var(--s-zero, 0px);
    --nav-ver-item-corner: var(--corner-zero, 0px);
    --nav-ver-item-selected-active-line: var(--line-2, 2px);
    --nav-ver-item-hover-active-line: var(--line-zero, 0px);
    --nav-group-height: var(--s-10, 40px);
    --nav-group-font-size: var(--font-size-body-1, 14px);
    --nav-primary-border-width: var(--line-zero, 0px);
    --nav-primary-text-style: var(--font-weight-2, normal);
    --nav-primary-shadow: var(--shadow-2, 4px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --nav-primary-sub-nav-text-style: var(--font-weight-2, normal);
    --nav-primary-group-text-color: var(--color-text1-2, #999999);
    --nav-primary-group-text-style: var(--font-weight-2, normal);
    --nav-primary-item-hover-text-color: var(--color-white, #FFFFFF);
    --nav-primary-item-hover-text-style: var(--font-weight-2, normal);
    --nav-primary-item-hover-active-color: var(--color-brand1-6, #209BFA);
    --nav-primary-item-selected-text-color: var(--color-white, #FFFFFF);
    --nav-primary-item-selected-text-style: var(--font-weight-3, bold);
    --nav-primary-item-childselected-bg-color: var(--color-transparent, transparent);
    --nav-primary-item-childselected-text-color: var(--color-white, #FFFFFF);
    --nav-primary-item-childselected-text-style: var(--font-weight-3, bold);
    --nav-primary-item-selected-active-color: var(--color-brand1-6, #209BFA);
    --nav-primary-sub-nav-selected-text-color: var(--color-white, #FFFFFF);
    --nav-primary-sub-nav-active-color: var(--color-brand1-6, #209BFA);
    --nav-primary-item-disabled-text-color: var(--color-text1-1, #CCCCCC);
    --nav-primary-item-opened-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-bg-color: var(--color-brand1-6, #209BFA);
    --nav-secondary-border-width: var(--line-zero, 0px);
    --nav-secondary-border-color: var(--color-brand1-6, #209BFA);
    --nav-secondary-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-text-style: var(--font-weight-2, normal);
    --nav-secondary-shadow: var(--shadow-2, 4px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --nav-secondary-sub-nav-bg-color: var(--color-brand1-6, #209BFA);
    --nav-secondary-sub-nav-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-sub-nav-text-style: var(--font-weight-2, normal);
    --nav-secondary-group-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-group-text-style: var(--font-weight-2, normal);
    --nav-secondary-item-hover-bg-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-item-hover-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-item-hover-text-style: var(--font-weight-2, normal);
    --nav-secondary-item-hover-active-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-sub-nav-hover-bg-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-sub-nav-hover-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-item-selected-bg-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-item-selected-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-item-selected-text-style: var(--font-weight-3, bold);
    --nav-secondary-item-childselected-bg-color: var(--color-transparent, transparent);
    --nav-secondary-item-childselected-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-item-childselected-text-style: var(--font-weight-3, bold);
    --nav-secondary-item-selected-active-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-sub-nav-selected-bg-color: var(--color-brand1-9, #1274E7);
    --nav-secondary-sub-nav-selected-text-color: var(--color-white, #FFFFFF);
    --nav-secondary-sub-nav-active-color: var(--color-brand1-6, #209BFA);
    --nav-secondary-item-disabled-text-color: var(--color-brand1-1, #ADD9FF);
    --nav-secondary-item-opened-bg-color: var(--color-transparent, transparent);
    --nav-secondary-item-opened-text-color: var(--color-white, #FFFFFF);
    --nav-normal-bg-color: var(--color-white, #FFFFFF);
    --nav-normal-border-color: var(--color-line1-1, #EEEEEE);
    --nav-normal-text-style: var(--font-weight-2, normal);
    --nav-normal-border-line: var(--line-1, 1px);
    --nav-normal-shadow: var(--shadow-2, 4px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --nav-normal-sub-nav-text-style: var(--font-weight-2, normal);
    --nav-normal-group-text-color: var(--color-text1-2, #999999);
    --nav-normal-group-text-style: var(--font-weight-2, normal);
    --nav-normal-item-hover-bg-color: var(--color-white, #FFFFFF);
    --nav-normal-sub-nav-hover-bg-color: var(--color-fill1-2, #F9F9F9);
    --nav-normal-item-selected-text-color: var(--color-brand1-6, #209BFA);
    --nav-normal-item-selected-text-style: var(--font-weight-3, bold);
    --nav-normal-item-childselected-bg-color: var(--color-transparent, transparent);
    --nav-normal-item-childselected-text-color: var(--color-brand1-6, #209BFA);
    --nav-normal-item-childselected-text-style: var(--font-weight-2, normal);
    --nav-normal-item-selected-active-color: var(--color-brand1-6, #209BFA);
    --nav-normal-sub-nav-selected-text-color: var(--color-brand1-6, #209BFA);
    --nav-normal-sub-nav-active-color: var(--color-brand1-6, #209BFA);
    --nav-normal-item-disabled-text-color: var(--color-text1-2, #999999);
    --nav-normal-item-opened-bg-color: var(--color-transparent, transparent);
    --nav-line-bg-color: var(--color-transparent, transparent);
    --nav-line-border-color: var(--color-line1-2, #E6E6E6);
    --nav-line-text-color: var(--color-text1-4, #333333);
    --nav-line-text-style: var(--font-weight-2, normal);
    --nav-line-border-line: var(--line-1, 1px);
    --nav-line-sub-nav-bg-color: var(--color-transparent, transparent);
    --nav-line-sub-nav-text-color: var(--color-text1-4, #333333);
    --nav-line-sub-nav-text-style: var(--font-weight-2, normal);
    --nav-line-item-hover-bg-color: var(--color-transparent, transparent);
    --nav-line-item-hover-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-item-hover-text-style: var(--font-weight-2, normal);
    --nav-line-item-hover-active-color: var(--color-brand1-6, #209BFA);
    --nav-line-sub-nav-hover-bg-color: var(--color-transparent, transparent);
    --nav-line-sub-nav-hover-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-item-selected-bg-color: var(--color-transparent, transparent);
    --nav-line-item-selected-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-item-selected-text-style: var(--font-weight-3, bold);
    --nav-line-item-childselected-bg-color: var(--color-transparent, transparent);
    --nav-line-item-childselected-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-item-childselected-text-style: var(--font-weight-2, normal);
    --nav-line-item-selected-active-color: var(--color-brand1-6, #209BFA);
    --nav-line-sub-nav-selected-bg-color: var(--color-transparent, transparent);
    --nav-line-sub-nav-selected-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-sub-nav-active-color: var(--color-brand1-6, #209BFA);
    --nav-line-item-disabled-text-color: var(--color-text1-2, #999999);
    --nav-line-item-opened-bg-color: var(--color-transparent, transparent);
    --nav-line-item-opened-text-color: var(--color-brand1-6, #209BFA);
    --nav-line-group-text-color: var(--color-text1-2, #999999);
    --nav-line-group-text-style: var(--font-weight-2, normal);
    /* ------------------------ checkbox ------------------------ */
    --checkbox-circle-size: var(--icon-xs, 12px);
    --checkbox-size: var(--s-4, 16px);
    --checkbox-border-radius: var(--corner-1, 3px);
    --checkbox-border-width: var(--line-1, 1px);
    --checkbox-shadow: var(--shadow-zero, none);
    --checkbox-border-color: var(--color-line1-3, #DDDDDD);
    --checkbox-hovered-border-color: var(--color-brand1-6, #209BFA);
    --checkbox-checked-border-color: var(--color-transparent, transparent);
    --checkbox-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --checkbox-checked-hovered-border-color: var(--color-transparent, transparent);
    --checkbox-checked-circle-color: var(--color-white, #FFFFFF);
    --checkbox-label-color: var(--color-text1-4, #333333);
    --checkbox-disabled-label-color: var(--color-text1-1, #CCCCCC);
    --checkbox-disabled-circle-color: var(--color-text1-1, #CCCCCC);
    --checkbox-checked-hovered-circle-color: var(--color-white, #FFFFFF);
    --checkbox-bg-color: var(--color-white, #FFFFFF);
    --checkbox-checked-bg-color: var(--color-brand1-6, #209BFA);
    --checkbox-hovered-bg-color: var(--color-brand1-1, #ADD9FF);
    --checkbox-checked-hovered-bg-color: var(--color-brand1-9, #1274E7);
    --checkbox-disabled-bg-color: var(--color-fill1-1, #FAFAFA);
    --checkbox-font-size: var(--font-size-body-1, 14px);
    --checkbox-margin-left: var(--s-1, 4px);
    --checkbox-semi-select-icon-content: var(--icon-content-semi-select, "");
    --checkbox-select-icon-content: var(--icon-content-select, "");
    /* ------------------------ collapse ------------------------ */
    --collapse-icon-size: var(--icon-s, 16px);
    --collapse-border-width: var(--line-1, 1px);
    --collapse-border-corner: var(--corner-1, 3px);
    --collapse-border-color: var(--color-line1-2, #E6E6E6);
    --collapse-panel-border-color: var(--color-line1-2, #E6E6E6);
    --collapse-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --collapse-title-border-width: var(--line-1, 1px);
    --collapse-title-height: var(--s-5, 20px);
    --collapse-title-bg-color: var(--color-fill1-2, #F9F9F9);
    --collapse-title-hover-bg-color: var(--color-fill1-3, #F5F5F5);
    --collapse-title-disabled-bg-color: var(--color-fill1-2, #F9F9F9);
    --collapse-title-font-color: var(--color-text1-4, #333333);
    --collapse-title-font-disabled-color: var(--color-text1-1, #CCCCCC);
    --collapse-title-hover-font-color: var(--color-text1-4, #333333);
    --collapse-title-font-size: var(--font-size-body-2, 14px);
    --collapse-title-font-weight: var(--font-weight-2, normal);
    --collapse-title-hover-font-weight: var(--font-weight-2, normal);
    --collapse-title-padding-tb: var(--s-2, 8px);
    --collapse-icon-color: var(--color-text1-4, #333333);
    --collapse-icon-hover-color: var(--color-text1-4, #333333);
    --collapse-icon-margin-r: var(--s-2, 8px);
    --collapse-icon-margin-l: var(--s-3, 12px);
    --collapse-content-bg-color: var(--color-white, #FFFFFF);
    --collapse-content-padding-x: var(--s-4, 16px);
    --collapse-content-padding-y: var(--s-3, 12px);
    --collapse-content-font-size: var(--font-size-body-1, 14px);
    --collapse-content-color: var(--color-text1-3, #666666);
    --collapse-fold-icon-content: var(--icon-content-arrow-right, "");
    --collapse-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    /* ------------------------ upload ------------------------ */
    --upload-text-list-close-icon-size: var(--icon-s, 16px);
    --upload-image-list-close-icon-size: var(--icon-s, 16px);
    --upload-disable-text-color: var(--color-text1-1, #CCCCCC);
    --upload-disable-border-color: var(--color-line1-1, #EEEEEE);
    --upload-text-list-height: var(--s-10, 40px);
    --upload-text-list-padding-left-right: var(--s-2, 8px);
    --upload-text-list-font-size: var(--font-size-body-1, 14px);
    --upload-text-list-close-icon-right: var(--s-3, 12px);
    --upload-text-list-corner: var(--corner-zero, 0px);
    --upload-text-list-bg-color: var(--color-fill1-2, #F9F9F9);
    --upload-text-list-bg-color-hover: var(--color-fill1-2, #F9F9F9);
    --upload-text-list-bg-color-error: var(--color-error-1, #FFECE4);
    --upload-text-list-error-text-color: var(--color-error-3, #D23C26);
    --upload-text-list-close-icon-color: var(--color-text1-2, #999999);
    --upload-text-list-close-icon-color-hover: var(--color-text1-3, #666666);
    --upload-text-list-name-font-color: var(--color-text1-4, #333333);
    --upload-text-list-name-font-color-hover: var(--color-brand1-6, #209BFA);
    --upload-text-list-size-font-color: var(--color-text1-2, #999999);
    --upload-text-list-size-font-color-hover: var(--color-brand1-6, #209BFA);
    --upload-text-list-progressbar-height: var(--s-2, 8px);
    --upload-image-list-item-padding: var(--s-2, 8px);
    --upload-image-list-item-picture-size: var(--s-12, 48px);
    --upload-image-list-item-picture-icon-size: var(--icon-l, 24px);
    --upload-image-list-item-picture-border-width: var(--line-1, 1px);
    --upload-image-list-item-picture-corner: var(--corner-zero, 0px);
    --upload-image-list-item-thumbnail-font-color: var(--color-text1-1, #CCCCCC);
    --upload-image-list-item-font-size: var(--font-size-body-1, 14px);
    --upload-image-list-close-icon-right: var(--s-1, 4px);
    --upload-image-list-item-border-width: var(--line-1, 1px);
    --upload-image-list-item-bg-color: var(--color-white, #FFFFFF);
    --upload-image-list-item-uploading-bg-color: var(--color-white, #FFFFFF);
    --upload-image-list-item-error-bg-color: var(--color-white, #FFFFFF);
    --upload-image-list-close-icon-color: var(--color-text1-2, #999999);
    --upload-image-list-close-icon-color-hover: var(--color-text1-3, #666666);
    --upload-image-list-item-border-color: var(--color-line1-2, #E6E6E6);
    --upload-image-list-item-border-color-error: var(--color-error-3, #D23C26);
    --upload-image-list-item-border-color-hover: var(--color-brand1-6, #209BFA);
    --upload-image-list-item-picture-border-color: var(--color-line1-2, #E6E6E6);
    --upload-image-list-item-picture-background-color: var(--color-fill1-2, #F9F9F9);
    --upload-image-list-progressbar-height: var(--s-2, 8px);
    --upload-card-size: var(--s-25, 100px);
    --upload-card-margin-bottom: var(--s-4, 16px);
    --upload-card-add-icon-size: var(--icon-l, 24px);
    --upload-card-add-text-size: var(--font-size-body-1, 14px);
    --upload-card-border-radius: var(--corner-zero, 0px);
    --upload-card-add-text-margin-top: var(--s-3, 12px);
    --upload-card-bg-color: var(--color-white, #FFFFFF);
    --upload-card-border-style: var(--line-dashed, dashed);
    --upload-card-border-color: var(--color-line1-3, #DDDDDD);
    --upload-card-hover-border-color: var(--color-brand1-6, #209BFA);
    --upload-card-font-color: var(--color-text1-3, #666666);
    --upload-card-icon-color: var(--color-line1-3, #DDDDDD);
    --upload-card-hover-font-color: var(--color-brand1-6, #209BFA);
    --upload-card-add-icon: var(--icon-content-add, "");
    --upload-card-list-bg-color: var(--color-transparent, transparent);
    --upload-card-list-uploading-bg-color: var(--color-fill1-1, #FAFAFA);
    --upload-card-list-bg-color-error: var(--color-fill1-1, #FAFAFA);
    --upload-card-list-margin-right: var(--s-3, 12px);
    --upload-card-list-padding: var(--s-zero, 0px);
    --upload-card-list-corner: var(--corner-zero, 0px);
    --upload-card-list-border-color: var(--color-line1-3, #DDDDDD);
    --upload-card-list-border-color-error: var(--color-error-3, #D23C26);
    --upload-card-list-thumbnail-font-color: var(--color-text1-1, #CCCCCC);
    --upload-card-list-thumbnail-font-size: var(--font-size-caption, 12px);
    --upload-card-list-thumbnail-icon-size: var(--icon-xxl, 48px);
    --upload-card-list-name-margin-top: var(--s-1, 4px);
    --upload-card-list-name-font-size: var(--font-size-caption, 12px);
    --upload-card-list-name-font-color: var(--color-text1-3, #666666);
    --upload-card-list-progressbar-height: var(--s-2, 8px);
    --upload-select-card-tool-bg-color: var(--color-black, #000000);
    --upload-select-card-tool-bg-opacity: .7;
    --upload-drag-zone-border-color: var(--color-line1-3, #DDDDDD);
    --upload-drag-zone-over-border-color: var(--color-brand1-6, #209BFA);
    --upload-drag-zone-corner: var(--corner-1, 3px);
    --upload-drag-zone-font-size: var(--font-size-body-2, 14px);
    --upload-drag-zone-icon-size: var(--s-6, 24px);
    --upload-drag-zone-hint-font-size: var(--font-size-caption, 12px);
    --upload-drag-zone-bg-color: var(--color-transparent, transparent);
    --upload-drag-zone-upload-icon: var(--icon-content-upload, "");
    --upload-drag-zone-upload-icon-color: var(--color-text1-3, #666666);
    --upload-drag-zone-upload-normal-title-color: var(--color-text1-3, #666666);
    --upload-drag-zone-upload-normal-hint-color: var(--color-text1-2, #999999);
    /* ------------------------ loading ------------------------ */
    --loading-large-size: var(--s-10, 40px);
    --loading-medium-size: var(--s-6, 24px);
    --loading-icon-size: var(--icon-xl, 32px);
    --loading-dot-color: var(--color-brand1-6, #209BFA);
    --loading-large-dot-size: var(--icon-xs, 12px);
    --loading-medium-dot-size: var(--icon-xxs, 8px);
    /* ------------------------ drawer ------------------------ */
    --drawer-close-size: var(--icon-s, 16px);
    --drawer-title-font-size: var(--font-size-subhead, 16px);
    --drawer-title-padding-top: var(--s-3, 12px);
    --drawer-title-padding-bottom: var(--s-3, 12px);
    --drawer-title-padding-left-right: var(--s-5, 20px);
    --drawer-title-border-width: var(--line-1, 1px);
    --drawer-content-font-size: var(--font-size-body-1, 14px);
    --drawer-content-padding-top: var(--s-5, 20px);
    --drawer-content-padding-bottom: var(--s-5, 20px);
    --drawer-content-padding-left-right: var(--s-5, 20px);
    --drawer-close-top: var(--s-3, 12px);
    --drawer-close-right: var(--s-3, 12px);
    --drawer-bg: var(--color-white, #FFFFFF);
    --drawer-border-width: var(--line-1, 1px);
    --drawer-border-color: var(--color-line1-2, #E6E6E6);
    --drawer-border-style: var(--line-solid, solid);
    --drawer-corner: var(--corner-1, 3px);
    --drawer-shadow: var(--shadow-2-down, 0px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --drawer-title-color: var(--color-text1-4, #333333);
    --drawer-title-bg-color: var(--color-white, #FFFFFF);
    --drawer-title-border-color: var(--color-line1-2, #E6E6E6);
    --drawer-content-color: var(--color-text1-3, #666666);
    --drawer-close-color: var(--color-text1-2, #999999);
    --drawer-close-color-hovered: var(--color-text1-4, #333333);
    --drawer-close-bg-hovered: var(--color-transparent, transparent);
    /* ------------------------ card ------------------------ */
    --card-border-width: var(--line-zero, 0px);
    --card-head-main-height: var(--s-16, 64px);
    --card-head-main-margin-top: var(--s-zero, 0px);
    --card-padding-lr: var(--s-6, 24px);
    --card-body-show-divider-padding-top: var(--s-5, 20px);
    --card-body-padding-bottom: var(--s-5, 20px);
    --card-corner: var(--corner-1, 3px);
    --card-head-padding-bottom: var(--s-3, 12px);
    --card-head-bottom-border-width: var(--line-1, 1px);
    --card-head-main-margin-bottom: var(--s-zero, 0px);
    --card-title-font-size: var(--font-size-subhead, 16px);
    --card-title-font-weight: var(--font-weight-2, normal);
    --card-sub-title-font-size: var(--font-size-caption, 12px);
    --card-title-extra-font-size: var(--font-size-body-1, 14px);
    --card-title-bullet-width: var(--line-3, 3px);
    --card-title-bullet-height: var(--s-4, 16px);
    --card-title-padding-left: var(--s-2, 8px);
    --card-sub-title-padding-left: var(--s-2, 8px);
    --card-body-hide-divider-padding-top: var(--s-zero, 0px);
    --card-content-font-size: var(--font-size-body-1, 14px);
    --card-content-line-height: var(--font-lineheight-2, 1.5);
    --card-more-btn-height: var(--s-4, 16px);
    --card-more-btn-padding-top: var(--s-2, 8px);
    --card-more-btn-font-size: var(--font-size-body-1, 14px);
    --card-shadow: var(--shadow-zero, none);
    --card-border-style: var(--line-solid, solid);
    --card-border-color: var(--color-line1-2, #E6E6E6);
    --card-background: var(--color-white, #FFFFFF);
    --card-header-background: var(--color-white, #FFFFFF);
    --card-title-color: var(--color-text1-4, #333333);
    --card-sub-title-color: var(--color-text1-3, #666666);
    --card-title-extra-color: var(--color-link-1, #298DFF);
    --card-title-bullet-color: var(--color-brand1-6, #209BFA);
    --card-content-color: var(--color-text1-3, #666666);
    --card-head-bottom-border-color: var(--color-line1-1, #EEEEEE);
    --card-more-btn-color: var(--color-link-1, #298DFF);
    --card-more-btn-background: var(--color-white, #FFFFFF);
    /* ------------------------ breadcrumb ------------------------ */
    --breadcrumb-text-current-weight: var(--font-weight-3, bold);
    --breadcrumb-size-m-icon-size: var(--icon-s, 16px);
    --breadcrumb-icon-color: var(--color-text1-2, #999999);
    --breadcrumb-size-m-font-size: var(--font-size-caption, 12px);
    --breadcrumb-size-ellipsis-font-size: var(--font-size-caption, 12px);
    --breadcrumb-size-m-icon-margin: var(--s-2, 8px);
    --breadcrumb-text-color: var(--color-text1-3, #666666);
    --breadcrumb-text-current-color: var(--color-text1-4, #333333);
    --breadcrumb-text-ellipsis-color: var(--color-text1-3, #666666);
    --breadcrumb-text-keyword-color: var(--color-brand1-6, #209BFA);
    --breadcrumb-icon-sep-content: var(--icon-content-arrow-right, "");
    --breadcrumb-text-color-hover: var(--color-brand1-6, #209BFA);
    --breadcrumb-text-current-color-hover: var(--color-brand1-6, #209BFA);
    --breadcrumb-text-keyword-color-hover: var(--color-brand1-6, #209BFA);
    /* ------------------------ calendar ------------------------ */
    --calendar-panel-header-background: var(--color-white, #FFFFFF);
    --calendar-btn-arrow-size: var(--icon-s, 16px);
    --calendar-btn-arrow-color: var(--color-text1-3, #666666);
    --calendar-btn-date-color: var(--color-black, #000000);
    --calendar-card-table-cell-hover-background: var(--color-notice-1, #E4F3FE);
    --calendar-card-table-cell-hover-border-color: var(--color-notice-1, #E4F3FE);
    --calendar-card-table-cell-inrange-background: var(--color-notice-1, #E4F3FE);
    --calendar-card-table-cell-inrange-border-color: var(--color-notice-1, #E4F3FE);
    --calendar-btn-arrow-color-hover: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-header-margin-bottom: var(--s-2, 8px);
    --calendar-fullscreen-table-head-font-weight: var(--font-weight-3, bold);
    --calendar-fullscreen-table-head-font-size: var(--font-size-subhead, 16px);
    --calendar-fullscreen-table-head-padding-r: var(--s-3, 12px);
    --calendar-fullscreen-table-head-padding-b: var(--s-1, 4px);
    --calendar-fullscreen-table-cell-font-size: var(--font-size-body-2, 14px);
    --calendar-fullscreen-table-cell-boder-top-width: var(--line-2, 2px);
    --calendar-fullscreen-table-cell-margin-tb: var(--s-zero, 0px);
    --calendar-fullscreen-table-cell-margin-lr: var(--s-1, 4px);
    --calendar-fullscreen-table-cell-padding-tb: var(--s-1, 4px);
    --calendar-fullscreen-table-cell-padding-lr: var(--s-2, 8px);
    --calendar-fullscreen-table-cell-min-height: var(--s-20, 80px);
    --calendar-fullscreen-table-head-color: var(--color-text1-4, #333333);
    --calendar-fullscreen-table-cell-normal-background: var(--color-white, #FFFFFF);
    --calendar-fullscreen-table-cell-normal-color: var(--color-text1-4, #333333);
    --calendar-fullscreen-table-cell-normal-border-color: var(--color-line1-2, #E6E6E6);
    --calendar-fullscreen-table-cell-hover-background: var(--color-brand1-1, #ADD9FF);
    --calendar-fullscreen-table-cell-hover-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-hover-border-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-select-background: var(--color-brand1-1, #ADD9FF);
    --calendar-fullscreen-table-cell-select-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-select-border-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-select-font-weight: var(--font-weight-3, bold);
    --calendar-fullscreen-table-cell-current-background: var(--color-white, #FFFFFF);
    --calendar-fullscreen-table-cell-current-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-current-border-color: var(--color-brand1-6, #209BFA);
    --calendar-fullscreen-table-cell-current-font-weight: var(--font-weight-3, bold);
    --calendar-fullscreen-table-cell-other-background: var(--color-transparent, transparent);
    --calendar-fullscreen-table-cell-other-color: var(--color-text1-1, #CCCCCC);
    --calendar-fullscreen-table-cell-other-border-color: var(--color-transparent, transparent);
    --calendar-fullscreen-table-cell-disabled-background: var(--color-fill1-1, #FAFAFA);
    --calendar-fullscreen-table-cell-disabled-color: var(--color-text1-1, #CCCCCC);
    --calendar-fullscreen-table-cell-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --calendar-card-header-margin-bottom: var(--s-2, 8px);
    --calendar-card-table-head-font-size: var(--font-size-caption, 12px);
    --calendar-card-table-head-font-weight: var(--font-weight-2, normal);
    --calendar-card-table-cell-font-size: var(--font-size-caption, 12px);
    --calendar-card-table-cell-date-border-radius: var(--corner-1, 3px);
    --calendar-card-table-cell-date-width: var(--s-6, 24px);
    --calendar-card-table-cell-date-height: var(--s-6, 24px);
    --calendar-card-table-cell-month-border-radius: var(--corner-1, 3px);
    --calendar-card-table-cell-month-width: var(--s-15, 60px);
    --calendar-card-table-cell-month-height: var(--s-6, 24px);
    --calendar-card-table-cell-year-border-radius: var(--corner-1, 3px);
    --calendar-card-table-cell-year-width: var(--s-12, 48px);
    --calendar-card-table-cell-year-height: var(--s-6, 24px);
    --calendar-card-table-head-color: var(--color-text1-2, #999999);
    --calendar-card-table-cell-corner: var(--corner-zero, 0px);
    --calendar-card-table-cell-normal-background: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-normal-color: var(--color-text1-3, #666666);
    --calendar-card-table-cell-normal-border-color: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-hover-color: var(--color-brand1-6, #209BFA);
    --calendar-card-table-cell-select-background: var(--color-brand1-6, #209BFA);
    --calendar-card-table-cell-select-color: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-select-border-color: var(--color-brand1-6, #209BFA);
    --calendar-card-table-cell-select-font-weight: var(--font-weight-3, bold);
    --calendar-card-table-cell-other-background: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-other-color: var(--color-text1-1, #CCCCCC);
    --calendar-card-table-cell-other-border-color: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-disabled-background: var(--color-fill1-1, #FAFAFA);
    --calendar-card-table-cell-disabled-color: var(--color-text1-1, #CCCCCC);
    --calendar-card-table-cell-disabled-border-color: var(--color-fill1-1, #FAFAFA);
    --calendar-card-table-cell-current-background: var(--color-white, #FFFFFF);
    --calendar-card-table-cell-current-color: var(--color-brand1-6, #209BFA);
    --calendar-card-table-cell-current-border-color: var(--color-transparent, transparent);
    --calendar-card-table-cell-current-font-weight: var(--font-weight-3, bold);
    --calendar-card-table-cell-inrange-color: var(--color-brand1-6, #209BFA);
    --calendar-panel-header-margin-bottom: var(--s-2, 8px);
    --calendar-panel-header-height: var(--s-8, 32px);
    --calendar-panel-header-border-bottom-width: var(--line-1, 1px);
    --calendar-panel-header-border-bottom-color: var(--color-transparent, transparent);
    --calendar-btn-date-font-weight: var(--font-weight-3, bold);
    --calendar-btn-date-margin-lr: var(--s-1, 4px);
    --calendar-btn-arrow-single-offset-lr: var(--s-7, 28px);
    --calendar-btn-arrow-double-offset-lr: var(--s-2, 8px);
    --calendar-btn-date-color-hover: var(--color-white, #FFFFFF);
    --calendar-btn-arrow-content-prev: var(--icon-content-arrow-left, "");
    --calendar-btn-arrow-content-next: var(--icon-content-arrow-right, "");
    --calendar-btn-arrow-content-prev-super: var(--icon-content-arrow-double-left, "");
    --calendar-btn-arrow-content-next-super: var(--icon-content-arrow-double-right, "");
    /* ------------------------ shell ------------------------ */
    --shell-dark-navigation-ver-width: var(--s-50, 200px);
    --shell-light-navigation-ver-width: var(--s-50, 200px);
    --shell-brand-navigation-ver-width: var(--s-50, 200px);
    --shell-dark-header-background: var(--color-fill1-5, #222222);
    --shell-dark-navigation-ver-background: var(--color-fill1-5, #222222);
    --shell-brand-header-color: var(--color-white, #FFFFFF);
    --shell-brand-header-background: var(--color-other-1, #18263C);
    --shell-brand-navigation-ver-background: var(--color-white, #FFFFFF);
    --shell-light-header-color: var(--color-black, #000000);
    --shell-light-header-height: var(--s-13, 52px);
    --shell-light-header-background: var(--color-white, #FFFFFF);
    --shell-light-header-shadow: var(--shadow-zero, none);
    --shell-light-header-divider-size: var(--line-1, 1px);
    --shell-light-header-divider-style: var(--line-solid, solid);
    --shell-light-header-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-header-paddingLeft: var(--s-4, 16px);
    --shell-light-multitask-min-height: var(--s-10, 40px);
    --shell-light-multitask-background: var(--color-white, #FFFFFF);
    --shell-light-multitask-shadow: var(--shadow-zero, none);
    --shell-light-multitask-divider-size: var(--line-1, 1px);
    --shell-light-multitask-divider-style: var(--line-solid, solid);
    --shell-light-multitask-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-multitask-paddingLeft: var(--s-zero, 0px);
    --shell-light-navigation-hoz-marginLeft: var(--s-12, 48px);
    --shell-light-navigation-ver-shadow: var(--shadow-zero, none);
    --shell-light-navigation-ver-paddingTop: var(--s-2, 8px);
    --shell-light-navigation-ver-paddingBottom: var(--s-2, 8px);
    --shell-light-navigation-ver-width-mini: var(--s-15, 60px);
    --shell-light-navigation-ver-background: var(--color-white, #FFFFFF);
    --shell-light-navigation-ver-divider-size: var(--line-1, 1px);
    --shell-light-navigation-ver-divider-style: var(--line-solid, solid);
    --shell-light-navigation-ver-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-local-navigation-width: var(--s-42, 168px);
    --shell-light-local-navigation-background: var(--color-fill1-4, #F2F2F2);
    --shell-light-local-navigation-paddingTop: var(--s-2, 8px);
    --shell-light-local-navigation-paddingBottom: var(--s-2, 8px);
    --shell-light-local-navigation-shadow: var(--shadow-zero, none);
    --shell-light-local-navigation-divider-size: var(--line-1, 1px);
    --shell-light-local-navigation-divider-style: var(--line-solid, solid);
    --shell-light-local-navigation-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-appbar-background: var(--color-white, #FFFFFF);
    --shell-light-appbar-min-height: var(--s-12, 48px);
    --shell-light-appbar-shadow: var(--shadow-zero, none);
    --shell-light-appbar-paddingLeft: var(--s-6, 24px);
    --shell-light-appbar-divider-size: var(--line-1, 1px);
    --shell-light-appbar-divider-style: var(--line-solid, solid);
    --shell-light-appbar-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-content-background: var(--color-fill1-3, #F5F5F5);
    --shell-light-content-paddingLeft: var(--s-5, 20px);
    --shell-light-content-paddingTop: var(--s-5, 20px);
    --shell-light-footer-min-height: var(--s-14, 56px);
    --shell-light-footer-background: var(--color-transparent, transparent);
    --shell-light-footer-color: var(--color-text1-1, #CCCCCC);
    --shell-light-footer-font-size: var(--font-size-body-2, 14px);
    --shell-light-ancillary-width: var(--s-42, 168px);
    --shell-light-ancillary-background: var(--color-white, #FFFFFF);
    --shell-light-ancillary-paddingTop: var(--s-2, 8px);
    --shell-light-ancillary-paddingBottom: var(--s-2, 8px);
    --shell-light-ancillary-shadow: var(--shadow-zero, none);
    --shell-light-ancillary-divider-size: var(--line-1, 1px);
    --shell-light-ancillary-divider-style: var(--line-solid, solid);
    --shell-light-ancillary-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-tooldock-height: var(--s-13, 52px);
    --shell-light-tooldock-width: var(--s-13, 52px);
    --shell-light-tooldock-background: var(--color-fill1-4, #F2F2F2);
    --shell-light-tooldock-paddingTop: var(--s-2, 8px);
    --shell-light-tooldock-paddingBottom: var(--s-2, 8px);
    --shell-light-tooldock-shadow: var(--shadow-zero, none);
    --shell-light-tooldock-divider-size: var(--line-1, 1px);
    --shell-light-tooldock-divider-style: var(--line-solid, solid);
    --shell-light-tooldock-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-light-tooldock-item-paddingTop: var(--s-2, 8px);
    --shell-light-tooldock-item-color: var(--color-text1-3, #666666);
    --shell-light-tooldock-item-color-hover: var(--color-text1-4, #333333);
    --shell-light-tooldock-item-color-active: var(--color-text1-4, #333333);
    --shell-light-tooldock-item-background: var(--color-transparent, transparent);
    --shell-light-tooldock-item-background-hover: var(--color-fill1-3, #F5F5F5);
    --shell-light-tooldock-item-background-active: var(--color-fill1-3, #F5F5F5);
    --shell-dark-header-color: var(--color-white, #FFFFFF);
    --shell-dark-header-height: var(--s-13, 52px);
    --shell-dark-header-shadow: var(--shadow-1-down, 0px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --shell-dark-header-divider-size: var(--line-1, 1px);
    --shell-dark-header-divider-style: var(--line-solid, solid);
    --shell-dark-header-divider-color: #1F1F1F;
    --shell-dark-header-paddingLeft: var(--s-4, 16px);
    --shell-dark-multitask-min-height: var(--s-10, 40px);
    --shell-dark-multitask-background: var(--color-white, #FFFFFF);
    --shell-dark-multitask-shadow: var(--shadow-zero, none);
    --shell-dark-multitask-divider-size: var(--line-1, 1px);
    --shell-dark-multitask-divider-style: var(--line-solid, solid);
    --shell-dark-multitask-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-multitask-paddingLeft: var(--s-zero, 0px);
    --shell-dark-navigation-hoz-marginLeft: var(--s-12, 48px);
    --shell-dark-navigation-ver-shadow: var(--shadow-zero, none);
    --shell-dark-navigation-ver-paddingTop: var(--s-2, 8px);
    --shell-dark-navigation-ver-paddingBottom: var(--s-2, 8px);
    --shell-dark-navigation-ver-width-mini: var(--s-15, 60px);
    --shell-dark-navigation-ver-divider-size: var(--line-1, 1px);
    --shell-dark-navigation-ver-divider-style: var(--line-solid, solid);
    --shell-dark-navigation-ver-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-local-navigation-width: var(--s-42, 168px);
    --shell-dark-local-navigation-background: var(--color-fill1-4, #F2F2F2);
    --shell-dark-local-navigation-paddingTop: var(--s-2, 8px);
    --shell-dark-local-navigation-paddingBottom: var(--s-2, 8px);
    --shell-dark-local-navigation-shadow: var(--shadow-zero, none);
    --shell-dark-local-navigation-divider-size: var(--line-1, 1px);
    --shell-dark-local-navigation-divider-style: var(--line-solid, solid);
    --shell-dark-local-navigation-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-appbar-background: var(--color-white, #FFFFFF);
    --shell-dark-appbar-min-height: var(--s-12, 48px);
    --shell-dark-appbar-shadow: var(--shadow-zero, none);
    --shell-dark-appbar-paddingLeft: var(--s-6, 24px);
    --shell-dark-appbar-divider-size: var(--line-1, 1px);
    --shell-dark-appbar-divider-style: var(--line-solid, solid);
    --shell-dark-appbar-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-content-background: var(--color-fill1-3, #F5F5F5);
    --shell-dark-content-paddingLeft: var(--s-5, 20px);
    --shell-dark-content-paddingTop: var(--s-5, 20px);
    --shell-dark-footer-min-height: var(--s-14, 56px);
    --shell-dark-footer-background: var(--color-transparent, transparent);
    --shell-dark-footer-color: var(--color-text1-1, #CCCCCC);
    --shell-dark-footer-font-size: var(--font-size-body-2, 14px);
    --shell-dark-ancillary-width: var(--s-42, 168px);
    --shell-dark-ancillary-background: var(--color-white, #FFFFFF);
    --shell-dark-ancillary-paddingTop: var(--s-2, 8px);
    --shell-dark-ancillary-paddingBottom: var(--s-2, 8px);
    --shell-dark-ancillary-shadow: var(--shadow-zero, none);
    --shell-dark-ancillary-divider-size: var(--line-1, 1px);
    --shell-dark-ancillary-divider-style: var(--line-solid, solid);
    --shell-dark-ancillary-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-tooldock-height: var(--s-13, 52px);
    --shell-dark-tooldock-width: var(--s-13, 52px);
    --shell-dark-tooldock-background: var(--color-fill1-4, #F2F2F2);
    --shell-dark-tooldock-paddingTop: var(--s-2, 8px);
    --shell-dark-tooldock-paddingBottom: var(--s-2, 8px);
    --shell-dark-tooldock-shadow: var(--shadow-zero, none);
    --shell-dark-tooldock-divider-size: var(--line-1, 1px);
    --shell-dark-tooldock-divider-style: var(--line-solid, solid);
    --shell-dark-tooldock-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-dark-tooldock-item-paddingTop: var(--s-2, 8px);
    --shell-dark-tooldock-item-color: var(--color-text1-3, #666666);
    --shell-dark-tooldock-item-color-hover: var(--color-text1-4, #333333);
    --shell-dark-tooldock-item-color-active: var(--color-text1-4, #333333);
    --shell-dark-tooldock-item-background: var(--color-transparent, transparent);
    --shell-dark-tooldock-item-background-hover: var(--color-fill1-3, #F5F5F5);
    --shell-dark-tooldock-item-background-active: var(--color-fill1-3, #F5F5F5);
    --shell-brand-header-height: var(--s-13, 52px);
    --shell-brand-header-shadow: var(--shadow-1-down, 0px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --shell-brand-header-divider-size: var(--line-1, 1px);
    --shell-brand-header-divider-style: var(--line-solid, solid);
    --shell-brand-header-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-header-paddingLeft: var(--s-4, 16px);
    --shell-brand-multitask-min-height: var(--s-10, 40px);
    --shell-brand-multitask-background: var(--color-white, #FFFFFF);
    --shell-brand-multitask-shadow: var(--shadow-zero, none);
    --shell-brand-multitask-divider-size: var(--line-1, 1px);
    --shell-brand-multitask-divider-style: var(--line-solid, solid);
    --shell-brand-multitask-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-multitask-paddingLeft: var(--s-zero, 0px);
    --shell-brand-navigation-hoz-marginLeft: var(--s-12, 48px);
    --shell-brand-navigation-ver-shadow: var(--shadow-zero, none);
    --shell-brand-navigation-ver-paddingTop: var(--s-2, 8px);
    --shell-brand-navigation-ver-paddingBottom: var(--s-2, 8px);
    --shell-brand-navigation-ver-width-mini: var(--s-15, 60px);
    --shell-brand-navigation-ver-divider-size: var(--line-1, 1px);
    --shell-brand-navigation-ver-divider-style: var(--line-solid, solid);
    --shell-brand-navigation-ver-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-local-navigation-width: var(--s-42, 168px);
    --shell-brand-local-navigation-background: var(--color-fill1-4, #F2F2F2);
    --shell-brand-local-navigation-paddingTop: var(--s-2, 8px);
    --shell-brand-local-navigation-paddingBottom: var(--s-2, 8px);
    --shell-brand-local-navigation-shadow: var(--shadow-zero, none);
    --shell-brand-local-navigation-divider-size: var(--line-1, 1px);
    --shell-brand-local-navigation-divider-style: var(--line-solid, solid);
    --shell-brand-local-navigation-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-appbar-background: var(--color-white, #FFFFFF);
    --shell-brand-appbar-min-height: var(--s-12, 48px);
    --shell-brand-appbar-shadow: var(--shadow-zero, none);
    --shell-brand-appbar-paddingLeft: var(--s-6, 24px);
    --shell-brand-appbar-divider-size: var(--line-1, 1px);
    --shell-brand-appbar-divider-style: var(--line-solid, solid);
    --shell-brand-appbar-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-content-background: var(--color-fill1-3, #F5F5F5);
    --shell-brand-content-paddingLeft: var(--s-5, 20px);
    --shell-brand-content-paddingTop: var(--s-5, 20px);
    --shell-brand-footer-min-height: var(--s-14, 56px);
    --shell-brand-footer-background: var(--color-transparent, transparent);
    --shell-brand-footer-color: var(--color-text1-1, #CCCCCC);
    --shell-brand-footer-font-size: var(--font-size-body-2, 14px);
    --shell-brand-ancillary-width: var(--s-42, 168px);
    --shell-brand-ancillary-background: var(--color-white, #FFFFFF);
    --shell-brand-ancillary-paddingTop: var(--s-2, 8px);
    --shell-brand-ancillary-paddingBottom: var(--s-2, 8px);
    --shell-brand-ancillary-shadow: var(--shadow-zero, none);
    --shell-brand-ancillary-divider-size: var(--line-1, 1px);
    --shell-brand-ancillary-divider-style: var(--line-solid, solid);
    --shell-brand-ancillary-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-tooldock-height: var(--s-13, 52px);
    --shell-brand-tooldock-width: var(--s-13, 52px);
    --shell-brand-tooldock-background: var(--color-fill1-4, #F2F2F2);
    --shell-brand-tooldock-paddingTop: var(--s-2, 8px);
    --shell-brand-tooldock-paddingBottom: var(--s-2, 8px);
    --shell-brand-tooldock-shadow: var(--shadow-zero, none);
    --shell-brand-tooldock-divider-size: var(--line-1, 1px);
    --shell-brand-tooldock-divider-style: var(--line-solid, solid);
    --shell-brand-tooldock-divider-color: var(--color-line1-1, #EEEEEE);
    --shell-brand-tooldock-item-paddingTop: var(--s-2, 8px);
    --shell-brand-tooldock-item-color: var(--color-text1-3, #666666);
    --shell-brand-tooldock-item-color-hover: var(--color-text1-4, #333333);
    --shell-brand-tooldock-item-color-active: var(--color-text1-4, #333333);
    --shell-brand-tooldock-item-background: var(--color-transparent, transparent);
    --shell-brand-tooldock-item-background-hover: var(--color-fill1-3, #F5F5F5);
    --shell-brand-tooldock-item-background-active: var(--color-fill1-3, #F5F5F5);
    /* ------------------------ form ------------------------ */
    --form-label-color: var(--color-text1-3, #666666);
    --form-label-padding-r: var(--s-3, 12px);
    --form-item-m-margin-b: var(--s-4, 16px);
    --form-item-l-margin-b: var(--s-5, 20px);
    --form-item-s-margin-b: var(--s-3, 12px);
    --form-inline-l-item-margin-r: var(--s-6, 24px);
    --form-inline-m-item-margin-r: var(--s-5, 20px);
    --form-inline-s-item-margin-r: var(--s-4, 16px);
    --form-help-margin-top: var(--s-1, 4px);
    --form-help-font-size: var(--font-size-caption, 12px);
    --form-help-color: var(--color-text1-2, #999999);
    --form-error-color: var(--color-error-3, #D23C26);
    --form-warning-color: var(--color-warning-3, #F1C826);
    --form-top-label-margin-b: 2px;
    /* ------------------------ cascader-select ------------------------ */
    --cascader-select: "cascader-select";
    /* ------------------------ timeline ------------------------ */
    --timeline-item-node-size: var(--s-4, 16px);
    --timeline-item-custom-node-size: var(--s-10, 40px);
    --timeline-item-custom-node-font-size: var(--font-size-caption, 12px);
    --timeline-item-node-padding: var(--s-1, 4px);
    --timeline-item-dot-size: var(--s-2, 8px);
    --timeline-item-icon-size: var(--icon-xs, 12px);
    --timeline-item-tail-size: var(--line-1, 1px);
    --timeline-item-left-content-width: var(--s-20, 80px);
    --timeline-item-content-margin-left: var(--s-3, 12px);
    --timeline-item-body-margin-top: var(--s-1, 4px);
    --timeline-item-title-font-size: var(--font-size-body-1, 14px);
    --timeline-item-title-font-weight: var(--font-weight-3, bold);
    --timeline-item-title-margin-top: var(--s-1, 4px);
    --timeline-item-time-margin-top: var(--s-1, 4px);
    --timeline-item-time-margin-bottom: var(--s-3, 12px);
    --timeline-item-body-font-size: var(--font-size-caption, 12px);
    --timeline-item-left-body-font-size: var(--font-size-caption, 12px);
    --timeline-item-time-font-size: var(--font-size-caption, 12px);
    --timeline-item-folder-font-size: var(--font-size-caption, 12px);
    --timeline-item-folder-margin-top: var(--s-1, 4px);
    --timeline-item-folder-margin-bottom: var(--s-1, 4px);
    --timeline-item-title-color: var(--color-text1-4, #333333);
    --timeline-item-body-color: var(--color-text1-3, #666666);
    --timeline-item-left-body-color: var(--color-text1-2, #999999);
    --timeline-item-time-color: var(--color-text1-2, #999999);
    --timeline-item-tail-color: var(--color-line1-2, #E6E6E6);
    --timeline-item-done-dot-size: var(--s-2, 8px);
    --timeline-item-done-background: var(--color-line1-3, #DDDDDD);
    --timeline-item-done-color: var(--color-white, #FFFFFF);
    --timeline-item-process-dot-size: var(--s-2, 8px);
    --timeline-item-process-background: var(--color-brand1-6, #209BFA);
    --timeline-item-process-color: var(--color-white, #FFFFFF);
    --timeline-item-success-dot-size: var(--s-2, 8px);
    --timeline-item-success-background: var(--color-success-3, #1AD78C);
    --timeline-item-success-color: var(--color-white, #FFFFFF);
    --timeline-item-error-dot-size: var(--s-2, 8px);
    --timeline-item-error-background: var(--color-error-3, #D23C26);
    --timeline-item-error-color: var(--color-white, #FFFFFF);
    /* ------------------------ menu-button ------------------------ */
    --menu-btn-disabled-icon-color: var(--color-text1-1, #CCCCCC);
    --menu-btn-ghost-light-disabled-icon-color: var(--btn-ghost-light-color-disabled, rgba(0, 0, 0, 0.1));
    --menu-btn-ghost-dark-disabled-icon-color: var(--btn-ghost-dark-color-disabled, rgba(255, 255, 255, 0.4));
    --menu-btn-pure-text-normal-icon-color: var(--color-text1-2, #999999);
    --menu-btn-pure-text-primary-icon-color: var(--color-white, #FFFFFF);
    --menu-btn-pure-text-secondary-icon-color: var(--color-brand1-6, #209BFA);
    --menu-btn-text-text-normal-icon-color: var(--color-text1-4, #333333);
    --menu-btn-text-text-primary-icon-color: var(--color-link-1, #298DFF);
    --menu-btn-ghost-light-icon-color: var(--color-text1-4, #333333);
    --menu-btn-ghost-dark-icon-color: var(--color-white, #FFFFFF);
    --menu-btn-fold-icon-content: var(--icon-content-arrow-down, "");
    --menu-btn-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    /* ------------------------ range ------------------------ */
    --range-size-m-track-height: var(--s-1, 4px);
    --range-size-m-scale-height: var(--s-3, 12px);
    --range-size-m-scale-item-border-width: var(--line-1, 1px);
    --range-size-m-slider-hw: var(--s-4, 16px);
    --range-size-slider-border-style: var(--line-solid, solid);
    --range-size-slider-border-width: var(--line-1, 1px);
    --range-size-m-mark-top: 30px;
    --range-size-m-mark-font-size: var(--font-size-body-1, 14px);
    --range-size-m-mark-font-weight: var(--font-weight-2, normal);
    --range-size-m-mark-font-lineheight: 20px;
    --range-size-m-track-radius: var(--corner-zero, 0px);
    --range-size-m-scale-radius: var(--corner-zero, 0px);
    --range-size-m-slider-shadow-moving: var(--shadow-2, 4px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --range-normal-unselected-color: var(--color-line1-3, #DDDDDD);
    --range-normal-selected-color: var(--color-brand1-6, #209BFA);
    --range-normal-slider-bg-color: var(--color-white, #FFFFFF);
    --range-normal-mark-color: var(--color-text1-2, #999999);
    --range-normal-mark-selected-color: var(--color-text1-4, #333333);
    --range-normal-unselected-color-hover: var(--color-line1-3, #DDDDDD);
    --range-normal-selected-color-hover: var(--color-brand1-6, #209BFA);
    --range-normal-slider-bg-color-hover: var(--color-white, #FFFFFF);
    --range-size-m-slider-shadow: var(--shadow-1, 1px 1px 3px 0px rgba(0, 0, 0, 0.12));
    --range-size-m-slider-shadow-hover: var(--shadow-3, 20px 20px 30px 0px rgba(0, 0, 0, 0.15));
    --range-normal-mark-color-hover: var(--color-text1-2, #999999);
    --range-normal-mark-selected-color-hover: var(--color-text1-4, #333333);
    --range-normal-slider-border-color-hover: var(--color-transparent, transparent);
    --range-normal-unselected-color-disabled: var(--color-line1-3, #DDDDDD);
    --range-normal-selected-color-disabled: var(--color-line1-4, #CCCCCC);
    --range-normal-slider-bg-color-disabled: var(--color-line1-1, #EEEEEE);
    --range-normal-slider-border-color: var(--color-line1-3, #DDDDDD);
    --range-normal-slider-border-color-disabled: var(--color-line1-1, #EEEEEE);
    --range-normal-mark-color-disabled: var(--color-text1-1, #CCCCCC);
    --range-normal-mark-selected-color-disabled: var(--color-text1-2, #999999);
    /* ------------------------ rating ------------------------ */
    --rating-small-text-margin-left: var(--s-2, 8px);
    --rating-small-icon-size: var(--icon-xs, 12px);
    --rating-small-font-size: var(--font-size-caption, 12px);
    --rating-medium-text-margin-left: var(--s-3, 12px);
    --rating-medium-icon-size: var(--icon-s, 16px);
    --rating-medium-font-size: var(--font-size-body-1, 14px);
    --rating-large-text-margin-left: var(--s-4, 16px);
    --rating-large-icon-size: var(--icon-m, 20px);
    --rating-large-font-size: var(--font-size-subhead, 16px);
    --rating-normal-underlay-color: var(--color-fill1-4, #F2F2F2);
    --rating-normal-overlay-color: var(--color-brand1-6, #209BFA);
    --rating-normal-overlay-hover-color: var(--color-brand1-6, #209BFA);
    --rating-grade-low-overlay-color: var(--color-text1-3, #666666);
    --rating-grade-low-overlay-hover-color: var(--color-text1-2, #999999);
    --rating-grade-high-overlay-color: var(--color-brand1-6, #209BFA);
    --rating-grade-high-overlay-hover-color: var(--color-brand1-6, #209BFA);
    --rating-grade-icon-content: var(--icon-content-favorites-filling, "");
    /* ------------------------ split-button ------------------------ */
    --split-btn-trigger-normal-icon-color: var(--color-text1-2, #999999);
    --split-btn-fold-icon-content: var(--icon-content-arrow-down, "");
    --split-btn-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    /* ------------------------ paragraph ------------------------ */
    --paragraph-text-color: var(--color-text1-4, #333333);
    --paragraph-s-font-size: var(--font-size-body-1, 14px);
    --paragraph-m-font-size: var(--font-size-body-2, 14px);
    --paragraph-s-line-height: var(--font-lineheight-2, 1.5);
    --paragraph-l-line-height: var(--font-lineheight-3, 1.7);
    /* ------------------------ typography ------------------------ */
    --typography-text-color: var(--color-text1-4, #333333);
    --typography-text-strong-font-weight: var(--font-weight-semi-bold, 600);
    --typography-text-code-corner: var(--corner-1, 3px);
    --typography-text-mark-color: var(--color-text1-4, #333333);
    --typography-text-mark-background: var(--color-warning-2, #FFE98F);
    --typography-text-code-color: var(--color-text1-4, #333333);
    --typography-text-code-background: var(--color-fill1-2, #F9F9F9);
    --typography-text-code-border-color: var(--color-line1-1, #EEEEEE);
    --typography-paragraph-font-size: var(--font-size-body-2, 14px);
    --typography-paragraph-line-height: var(--font-lineheight-2, 1.5);
    --typography-title-h1-font-size: var(--font-size-headline, 24px);
    --typography-title-h2-font-size: var(--font-size-title, 20px);
    --typography-title-h3-font-size: var(--font-size-subhead, 16px);
    --typography-title-h4-font-size: var(--font-size-subhead, 16px);
    --typography-title-h5-font-size: var(--font-size-body-2, 14px);
    --typography-title-h6-font-size: var(--font-size-body-1, 14px);
    --typography-title-font-weight: var(--font-weight-semi-bold, 600);
    /* ------------------------ badge ------------------------ */
    --badge-size-dot-width: var(--s-2, 8px);
    --badge-size-list-margin: var(--s-zero, 0px);
    --badge-size-count-config-height: var(--s-4, 16px);
    --badge-size-count-padding-lr: var(--s-1, 4px);
    --badge-size-custom-padding-lr: var(--s-1, 4px);
    --badge-size-count-font: var(--font-size-caption, 12px);
    --badge-color-bg: var(--color-error-3, #D23C26);
    --badge-size-count-border-radius: var(--s-2, 8px);
    --badge-dot-color-bg: var(--color-error-3, #D23C26);
    --badge-size-dot-border-radius: var(--s-2, 8px);
    --badge-size-custom-border-radius: var(--corner-1, 3px);
    /* ------------------------ select ------------------------ */
    --select-color: var(--color-text1-4, #333333);
    --select-hint-color: var(--color-text1-2, #999999);
    --select-highlight-color: var(--color-brand1-6, #209BFA);
    --select-highlight-font: var(--font-size-body-1, 14px);
    --select-l-lineheight: var(--s-6, 24px);
    --select-m-lineheight: var(--s-5, 20px);
    --select-s-lineheight: 14px;
    --select-fold-icon-content: var(--icon-content-arrow-down, "");
    --select-unfold-icon-content: var(--icon-reset, var(--icon-reset));
    /* ------------------------ message ------------------------ */
    --message-size-l-padding: var(--s-4, 16px);
    --message-size-l-border-width: var(--line-2, 2px);
    --message-size-l-title-content-padding-left: var(--s-3, 12px);
    --message-size-l-title-content-padding-right: var(--s-5, 20px);
    --message-size-l-title-font: var(--font-size-title, 20px);
    --message-size-l-content-margin-top: var(--s-2, 8px);
    --message-size-l-content-font: var(--font-size-body-1, 14px);
    --message-size-l-close-top: var(--s-4, 16px);
    --message-size-l-close-right: var(--s-4, 16px);
    --message-size-l-icon: var(--icon-l, 24px);
    --message-size-m-padding: var(--s-3, 12px);
    --message-size-m-border-width: var(--line-1, 1px);
    --message-size-m-title-content-padding-left: var(--s-2, 8px);
    --message-size-m-title-content-padding-right: var(--s-5, 20px);
    --message-size-m-title-font: var(--font-size-subhead, 16px);
    --message-size-m-content-margin-top: var(--s-2, 8px);
    --message-size-m-content-font: var(--font-size-body-1, 14px);
    --message-size-m-close-top: var(--s-3, 12px);
    --message-size-m-close-right: var(--s-3, 12px);
    --message-size-m-icon: var(--icon-s, 16px);
    --message-close-icon-size: var(--icon-xs, 12px);
    --message-size-l-border-radius: var(--corner-1, 3px);
    --message-size-m-border-radius: var(--corner-1, 3px);
    --message-size-l-border-radius-toast: var(--corner-1, 3px);
    --message-size-m-border-radius-toast: var(--corner-1, 3px);
    --message-border-style: var(--line-solid, solid);
    --message-border-style-toast: var(--line-solid, solid);
    --message-shadow-toast: var(--shadow-2-down, 0px 4px 8px 0px rgba(0, 0, 0, 0.12));
    --message-success-color-bg-inline: var(--color-success-1, #E5FFF5);
    --message-success-color-bg-addon: var(--color-transparent, transparent);
    --message-success-color-bg-toast: var(--color-white, #FFFFFF);
    --message-success-color-border-inline: var(--color-success-1, #E5FFF5);
    --message-success-color-border-toast: var(--color-white, #FFFFFF);
    --message-success-color-title-inline: var(--color-text1-4, #333333);
    --message-success-color-title-addon: var(--color-text1-4, #333333);
    --message-success-color-title-toast: var(--color-text1-4, #333333);
    --message-success-color-content-inline: var(--color-text1-3, #666666);
    --message-success-color-content-addon: var(--color-text1-3, #666666);
    --message-success-color-content-toast: var(--color-text1-3, #666666);
    --message-success-color-icon-inline: var(--color-success-3, #1AD78C);
    --message-success-color-icon-addon: var(--color-success-3, #1AD78C);
    --message-success-color-icon-toast: var(--color-success-3, #1AD78C);
    --message-error-color-bg-inline: var(--color-error-1, #FFECE4);
    --message-error-color-bg-addon: var(--color-transparent, transparent);
    --message-error-color-bg-toast: var(--color-white, #FFFFFF);
    --message-error-color-border-inline: var(--color-error-1, #FFECE4);
    --message-error-color-border-toast: var(--color-white, #FFFFFF);
    --message-error-color-title-inline: var(--color-text1-4, #333333);
    --message-error-color-title-addon: var(--color-text1-4, #333333);
    --message-error-color-title-toast: var(--color-text1-4, #333333);
    --message-error-color-content-inline: var(--color-text1-3, #666666);
    --message-error-color-content-addon: var(--color-text1-3, #666666);
    --message-error-color-content-toast: var(--color-text1-3, #666666);
    --message-error-color-icon-inline: var(--color-error-3, #D23C26);
    --message-error-color-icon-addon: var(--color-error-3, #D23C26);
    --message-error-color-icon-toast: var(--color-error-3, #D23C26);
    --message-warning-color-bg-inline: var(--color-warning-1, #FFF9E0);
    --message-warning-color-bg-addon: var(--color-transparent, transparent);
    --message-warning-color-bg-toast: var(--color-white, #FFFFFF);
    --message-warning-color-border-inline: var(--color-warning-1, #FFF9E0);
    --message-warning-color-border-toast: var(--color-white, #FFFFFF);
    --message-warning-color-title-inline: var(--color-text1-4, #333333);
    --message-warning-color-title-addon: var(--color-text1-4, #333333);
    --message-warning-color-title-toast: var(--color-text1-4, #333333);
    --message-warning-color-content-inline: var(--color-text1-3, #666666);
    --message-warning-color-content-addon: var(--color-text1-3, #666666);
    --message-warning-color-content-toast: var(--color-text1-3, #666666);
    --message-warning-color-icon-inline: var(--color-warning-3, #F1C826);
    --message-warning-color-icon-addon: var(--color-warning-3, #F1C826);
    --message-warning-color-icon-toast: var(--color-warning-3, #F1C826);
    --message-notice-color-bg-inline: var(--color-notice-1, #E4F3FE);
    --message-notice-color-bg-addon: var(--color-transparent, transparent);
    --message-notice-color-bg-toast: var(--color-white, #FFFFFF);
    --message-notice-color-border-inline: var(--color-notice-1, #E4F3FE);
    --message-notice-color-border-toast: var(--color-white, #FFFFFF);
    --message-notice-color-title-inline: var(--color-text1-4, #333333);
    --message-notice-color-title-addon: var(--color-text1-4, #333333);
    --message-notice-color-title-toast: var(--color-text1-4, #333333);
    --message-notice-color-content-inline: var(--color-text1-3, #666666);
    --message-notice-color-content-addon: var(--color-text1-3, #666666);
    --message-notice-color-content-toast: var(--color-text1-3, #666666);
    --message-notice-color-icon-inline: var(--color-notice-3, #298DFF);
    --message-notice-color-icon-addon: var(--color-notice-3, #298DFF);
    --message-notice-color-icon-toast: var(--color-notice-3, #298DFF);
    --message-help-color-bg-inline: var(--color-help-1, #FFF9E0);
    --message-help-color-bg-addon: var(--color-transparent, transparent);
    --message-help-color-bg-toast: var(--color-white, #FFFFFF);
    --message-help-color-border-inline: var(--color-help-1, #FFF9E0);
    --message-help-color-border-toast: var(--color-white, #FFFFFF);
    --message-help-color-title-inline: var(--color-text1-4, #333333);
    --message-help-color-title-addon: var(--color-text1-4, #333333);
    --message-help-color-title-toast: var(--color-text1-4, #333333);
    --message-help-color-content-inline: var(--color-text1-3, #666666);
    --message-help-color-content-addon: var(--color-text1-3, #666666);
    --message-help-color-content-toast: var(--color-text1-3, #666666);
    --message-help-color-icon-inline: var(--color-help-3, #F1C826);
    --message-help-color-icon-addon: var(--color-help-3, #F1C826);
    --message-help-color-icon-toast: var(--color-help-3, #F1C826);
    --message-loading-color-bg-inline: var(--color-white, #FFFFFF);
    --message-loading-color-bg-addon: var(--color-transparent, transparent);
    --message-loading-color-bg-toast: var(--color-white, #FFFFFF);
    --message-loading-color-border-inline: var(--color-white, #FFFFFF);
    --message-loading-color-border-toast: var(--color-white, #FFFFFF);
    --message-loading-color-title-inline: var(--color-text1-4, #333333);
    --message-loading-color-title-addon: var(--color-text1-4, #333333);
    --message-loading-color-title-toast: var(--color-text1-4, #333333);
    --message-loading-color-content-inline: var(--color-text1-3, #666666);
    --message-loading-color-content-addon: var(--color-text1-3, #666666);
    --message-loading-color-content-toast: var(--color-text1-3, #666666);
    --message-loading-color-icon-inline: var(--color-brand1-6, #209BFA);
    --message-loading-color-icon-addon: var(--color-brand1-6, #209BFA);
    --message-loading-color-icon-toast: var(--color-brand1-6, #209BFA);
    --message-close-icon-color: var(--color-text1-2, #999999);
    --message-hover-close-icon-color: var(--color-text1-3, #666666);
    --message-success-icon-content: var(--icon-content-success, "");
    --message-warning-icon-content: var(--icon-content-warning, "");
    --message-error-icon-content: var(--icon-content-error, "");
    --message-notice-icon-content: var(--icon-content-prompt, "");
    --message-help-icon-content: var(--icon-content-help, "");
    --message-loading-icon-content: var(--icon-content-loading, "");
    /* ------------------------ number-picker ------------------------ */
    --number-picker-normal-up-icon: var(--icon-content-arrow-up, "");
    --number-picker-normal-down-icon: var(--icon-content-arrow-down, "");
    --number-picker-normal-s-button-width: var(--s-5, 20px);
    --number-picker-normal-m-button-width: var(--s-5, 20px);
    --number-picker-normal-l-button-width: var(--s-5, 20px);
    --number-picker-normal-m-input-width: var(--s-20, 80px);
    --number-picker-normal-l-input-width: var(--s-20, 80px);
    --number-picker-normal-s-button-icon-size: var(--icon-xxs, 8px);
    --number-picker-normal-m-button-icon-size: var(--icon-xxs, 8px);
    --number-picker-normal-l-button-icon-size: var(--icon-xxs, 8px);
    --number-picker-inline-add-icon: var(--icon-content-add, "");
    --number-picker-inline-minus-icon: var(--icon-content-minus, "");
    --number-picker-inline-s-button-icon-size: var(--icon-xs, 12px);
    --number-picker-inline-s-button-margin: 2px;
    --number-picker-inline-s-button-corner: var(--corner-1, 3px);
    --number-picker-inline-m-button-icon-size: var(--icon-xs, 12px);
    --number-picker-inline-m-button-margin: 2px;
    --number-picker-inline-m-button-corner: var(--corner-1, 3px);
    --number-picker-inline-m-input-width: var(--s-25, 100px);
    --number-picker-inline-l-button-icon-size: var(--icon-xs, 12px);
    --number-picker-inline-l-button-margin: 2px;
    --number-picker-inline-l-button-corner: var(--corner-1, 3px);
    --number-picker-inline-l-input-width: var(--s-32, 128px);
    /* ------------------------ divider ------------------------ */
    --divider-hoz-size: var(--line-1, 1px);
    --divider-hoz-color: var(--color-line1-2, #E6E6E6);
    --divider-hoz-text-center-paddingLeft: var(--s-4, 16px);
    --divider-hoz-text-left-paddingLeft: var(--s-4, 16px);
    --divider-hoz-text-marginTop: var(--s-4, 16px);
    --divider-hoz-marginTop: var(--s-4, 16px);
    --divider-hoz-text-size: var(--font-size-subhead, 16px);
    --divider-hoz-text-weight: var(--font-weight-2, normal);
    --divider-hoz-text-color: var(--color-text1-4, #333333);
    --divider-ver-size: var(--line-1, 1px);
    --divider-ver-color: var(--color-line1-2, #E6E6E6);
    --divider-ver-marginLeft: var(--s-2, 8px);
    /* ------------------------ transfer ------------------------ */
    --transfer-operation-margin-left-right: var(--s-5, 20px);
    --transfer-operation-margin-gutter: var(--s-2, 8px);
    --transfer-operation-icon-size: var(--icon-xs, 12px);
    --transfer-panel-border-width: var(--line-1, 1px);
    --transfer-panel-header-padding-top-bottom: var(--s-2, 8px);
    --transfer-panel-header-padding-left-right: var(--s-5, 20px);
    --transfer-panel-header-text-size: var(--font-size-body-1, 14px);
    --transfer-panel-search-margin-top: var(--s-2, 8px);
    --transfer-panel-search-margin-left-right: var(--s-1, 4px);
    --transfer-panel-search-margin-bottom: var(--s-zero, 0px);
    --transfer-panel-list-width: var(--s-45, 180px);
    --transfer-panel-list-height: var(--s-40, 160px);
    --transfer-panel-footer-padding-top-bottom: var(--s-2, 8px);
    --transfer-panel-footer-padding-left-right: var(--s-5, 20px);
    --transfer-simple-move-icon: var(--icon-content-switch, "");
    --transfer-simple-move-icon-color: var(--color-line1-3, #DDDDDD);
    --transfer-panel-border-color: var(--color-line1-2, #E6E6E6);
    --transfer-panel-border-corner: var(--corner-1, 3px);
    --transfer-panel-background-color: var(--color-white, #FFFFFF);
    --transfer-panel-header-text-color: var(--color-text1-4, #333333);
    --transfer-panel-header-background-color: var(--color-fill1-1, #FAFAFA);
    --transfer-panel-search-icon-color: var(--color-text1-2, #999999);
    --transfer-panel-footer-text-color: var(--color-text1-4, #333333);
    --transfer-panel-footer-background-color: var(--color-white, #FFFFFF);
    --transfer-simple-panel-footer-text-color: var(--color-brand1-6, #209BFA);
    --transfer-simple-panel-item-hover-text-color: var(--color-brand1-6, #209BFA);
    --transfer-panel-footer-shadow: var(--shadow-zero, none);
    /* ------------------------ tree-select ------------------------ */
    --tree-select-padding-vertical: var(--s-2, 8px);
    --tree-select-padding-horizontal: var(--s-5, 20px);
    --tree-select-background: var(--color-white, #FFFFFF);
    /* ------------------------ time-picker ------------------------ */
    --time-picker-panel-width: var(--s-50, 200px);
    --time-picker-panel-background: var(--color-white, #FFFFFF);
    --time-picker-menu-border-width: var(--line-1, 1px);
    --time-picker-menu-title-font-size: var(--font-size-caption, 12px);
    --time-picker-menu-item-font-size: var(--font-size-caption, 12px);
    --time-picker-menu-title-height: var(--s-7, 28px);
    --time-picker-menu-item-height: var(--s-7, 28px);
    --time-picker-menu-border-color: var(--color-line1-3, #DDDDDD);
    --time-picker-menu-title-color: var(--color-text1-2, #999999);
    --time-picker-menu-title-background: var(--color-white, #FFFFFF);
    --time-pikcer-menu-title-font-weight: var(--font-weight-2, normal);
    --time-picker-menu-item-color: var(--color-text1-3, #666666);
    --time-picker-menu-item-color-hover: var(--color-text1-4, #333333);
    --time-picker-menu-item-color-selected: var(--color-text1-3, #666666);
    --time-picker-menu-item-color-disabled: var(--color-text1-1, #CCCCCC);
    --time-picker-menu-item-background: var(--color-white, #FFFFFF);
    --time-picker-menu-item-background-hover: var(--color-fill1-2, #F9F9F9);
    --time-picker-menu-item-background-selected: var(--color-fill1-2, #F9F9F9);
    --time-picker-menu-item-background-disabled: var(--color-white, #FFFFFF);
    --time-picker-menu-item-font-weight-selected: var(--font-weight-3, bold);
    --time-picker-clock-icon: var(--icon-content-clock, "");
    /* ------------------------ slider ------------------------ */
    --slick-dots-position-bottom: var(--s-3, 12px);
    --slick-dots-position-right: var(--s-5, 20px);
    --slick-dots-width: var(--s-2, 8px);
    --slick-dots-height: var(--s-2, 8px);
    --slick-dots-margin-lr: var(--s-1, 4px);
    --slick-dots-margin-tb: var(--s-zero, 0px);
    --slick-arrow-width-m: var(--s-7, 28px);
    --slick-arrow-width-l: var(--s-12, 48px);
    --slick-arrow-height-m: var(--s-14, 56px);
    --slick-arrow-height-l: var(--s-24, 96px);
    --slick-ver-arrow-width-m: var(--s-14, 56px);
    --slick-ver-arrow-width-l: var(--s-24, 96px);
    --slick-ver-arrow-height-m: var(--s-7, 28px);
    --slick-ver-arrow-height-l: var(--s-12, 48px);
    --slick-arrow-icon-m: var(--icon-m, 20px);
    --slick-arrow-icon-l: var(--icon-xl, 32px);
    --slick-arrow-position-lr-m: var(--s-1, 4px);
    --slick-arrow-position-lr-l: var(--s-2, 8px);
    --slick-ver-arrow-position-tb-m: var(--s-1, 4px);
    --slick-ver-arrow-position-tb-l: var(--s-4, 16px);
    --slick-track-padding-lr: var(--s-6, 24px);
    --slick-ver-track-padding-tb: var(--s-6, 24px);
    --slick-dots-background-color-normal: var(--color-black, #000000);
    --slick-dots-background-color-hover: var(--color-white, #FFFFFF);
    --slick-dots-background-color-selected: var(--color-brand1-6, #209BFA);
    --slick-dots-background-opacity-normal: .32;
    --slick-dots-background-opacity-hover: .5;
    --slick-dots-background-opacity-selected: 1;
    --slick-dots-border-width: var(--line-zero, 0px);
    --slick-dots-border-radius: var(--corner-circle, 50%);
    --slick-dots-border-color-normal: var(--color-white, #FFFFFF);
    --slick-dots-border-color-hover: var(--color-white, #FFFFFF);
    --slick-dots-border-color-selected: var(--color-white, #FFFFFF);
    --slick-dots-border-opacity-normal: 1;
    --slick-dots-border-opacity-hover: 1;
    --slick-dots-border-opacity-selected: 1;
    --slick-arrow-icon-color-normal: var(--color-white, #FFFFFF);
    --slick-arrow-icon-color-normal-outer: var(--color-text1-3, #666666);
    --slick-arrow-icon-color-hover: var(--color-white, #FFFFFF);
    --slick-arrow-icon-color-hover-outer: var(--color-text1-4, #333333);
    --slick-arrow-icon-color-disabled: var(--color-text1-1, #CCCCCC);
    --slick-arrow-icon-color-disabled-outer: var(--color-text1-1, #CCCCCC);
    --slick-arrow-bg-color-normal: var(--color-black, #000000);
    --slick-arrow-bg-color-hover: var(--color-black, #000000);
    --slick-arrow-bg-color-disabled: var(--color-fill1-1, #FAFAFA);
    --slick-arrow-bg-opacity-normal: .2;
    --slick-arrow-bg-opacity-hover: .4;
    --slick-arrow-bg-opacity-disabled: .5;
    --slick-arrow-bg-color-normal-outer: var(--color-transparent, transparent);
    --slick-arrow-bg-color-hover-outer: var(--color-transparent, transparent);
    --slick-arrow-bg-color-disabled-outer: var(--color-transparent, transparent);
    --slick-arrow-bg-opacity-normal-outer: .32;
    --slick-arrow-bg-opacity-hover-outer: .32;
    --slick-arrow-bg-opacity-disabled-outer: .32;
    --slick-arrow-corner-radius-outer: var(--s-zero, 0px);
    /* ------------------------ step ------------------------ */
    --step-arrow-item-height: var(--s-8, 32px);
    --step-arrow-item-title-size: var(--font-size-body-2, 14px);
    --step-arrow-item-title-weight: var(--font-weight-3, bold);
    --step-arrow-item-wait-background: var(--color-fill1-3, #F5F5F5);
    --step-arrow-item-title-wait-color: var(--color-text1-2, #999999);
    --step-arrow-item-process-background: var(--color-brand1-6, #209BFA);
    --step-arrow-item-title-process-color: var(--color-white, #FFFFFF);
    --step-arrow-item-finish-background: var(--color-brand1-1, #ADD9FF);
    --step-arrow-item-title-finish-color: var(--color-brand1-6, #209BFA);
    --step-arrow-item-disabled-background: var(--color-fill1-1, #FAFAFA);
    --step-arrow-item-title-disabled-color: var(--color-text1-1, #CCCCCC);
    --step-circle-item-node-padding: var(--s-2, 8px);
    --step-circle-item-node-border-width: var(--line-1, 1px);
    --step-circle-item-node-icon-size: var(--icon-xs, 12px);
    --step-circle-item-node-font-size: var(--font-size-caption, 12px);
    --step-circle-item-node-corner: var(--corner-circle, 50%);
    --step-circle-item-tail-size: var(--line-1, 1px);
    --step-circle-item-title-margin-top: var(--s-2, 8px);
    --step-circle-item-content-margin-top: var(--s-1, 4px);
    --step-circle-item-content-font-size: var(--font-size-caption, 12px);
    --step-circle-item-body-width: var(--s-25, 100px);
    --step-circle-item-title-size: var(--font-size-body-2, 14px);
    --step-circle-item-title-weight: var(--font-weight-3, bold);
    --step-circle-item-node-wait-size: var(--s-8, 32px);
    --step-circle-item-node-process-size: var(--s-8, 32px);
    --step-circle-item-node-finish-size: var(--s-8, 32px);
    --step-circle-item-node-disabled-size: var(--s-8, 32px);
    --step-circle-item-tail-wait-color: var(--color-line1-3, #DDDDDD);
    --step-circle-item-tail-process-color: var(--color-line1-3, #DDDDDD);
    --step-circle-item-tail-finish-color: var(--color-brand1-6, #209BFA);
    --step-circle-item-tail-disabled-color: var(--color-line1-1, #EEEEEE);
    --step-circle-item-node-wait-font-weight: var(--font-weight-2, normal);
    --step-circle-item-node-process-font-weight: var(--font-weight-2, normal);
    --step-circle-item-node-disabled-font-weight: var(--font-weight-2, normal);
    --step-circle-item-node-finish-font-weight: var(--font-weight-2, normal);
    --step-circle-item-title-wait-color: var(--color-text1-3, #666666);
    --step-circle-item-title-process-color: var(--color-text1-4, #333333);
    --step-circle-item-title-finish-color: var(--color-text1-3, #666666);
    --step-circle-item-title-disabled-color: var(--color-text1-1, #CCCCCC);
    --step-circle-item-node-wait-border-color: var(--color-line1-4, #CCCCCC);
    --step-circle-item-node-wait-background: var(--color-white, #FFFFFF);
    --step-circle-item-node-wait-color: var(--color-text1-3, #666666);
    --step-circle-item-node-process-border-color: var(--color-brand1-6, #209BFA);
    --step-circle-item-node-process-background: var(--color-brand1-6, #209BFA);
    --step-circle-item-node-process-color: var(--color-white, #FFFFFF);
    --step-circle-item-node-process-percent-color: var(--color-brand1-6, #209BFA);
    --step-circle-item-node-process-percent-size: var(--font-size-body-1, 14px);
    --step-circle-item-node-finish-border-color: var(--color-brand1-6, #209BFA);
    --step-circle-item-node-finish-background: var(--color-white, #FFFFFF);
    --step-circle-item-node-finish-color: var(--color-brand1-6, #209BFA);
    --step-circle-item-node-disabled-border-color: var(--color-line1-1, #EEEEEE);
    --step-circle-item-node-disabled-background: var(--color-white, #FFFFFF);
    --step-circle-item-node-disabled-color: var(--color-text1-1, #CCCCCC);
    --step-circle-vertical-item-node-padding: var(--s-2, 8px);
    --step-circle-vertical-item-body-margin-left: var(--s-4, 16px);
    --step-circle-vertical-item-title-margin-top: var(--s-2, 8px);
    --step-circle-vertical-item-content-margin-top: var(--s-1, 4px);
    --step-circle-item-content-color: var(--color-text1-3, #666666);
    --step-circle-item-tail-bg-color: var(--color-line1-1, #EEEEEE);
    --step-circle-vertical-item-tail-size: var(--line-1, 1px);
    --step-dot-item-dot-padding: var(--s-2, 8px);
    --step-dot-item-dot-icon-size: var(--icon-xs, 12px);
    --step-dot-item-dot-border-width: var(--line-1, 1px);
    --step-dot-item-body-width: var(--s-25, 100px);
    --step-dot-item-content-margin-top: var(--s-1, 4px);
    --step-dot-item-dot-corner: var(--corner-circle, 50%);
    --step-dot-item-title-size: var(--font-size-body-1, 14px);
    --step-dot-item-title-weight: var(--font-weight-3, bold);
    --step-dot-item-title-margin-top: var(--s-2, 8px);
    --step-dot-item-tail-size: var(--line-1, 1px);
    --step-dot-item-dot-wait-size: var(--s-3, 12px);
    --step-dot-item-dot-process-size: var(--s-3, 12px);
    --step-dot-item-dot-finish-size: var(--s-3, 12px);
    --step-dot-item-dot-disabled-size: var(--s-3, 12px);
    --step-dot-item-tail-wait-color: var(--color-line1-3, #DDDDDD);
    --step-dot-item-tail-process-color: var(--color-line1-3, #DDDDDD);
    --step-dot-item-tail-finish-color: var(--color-brand1-6, #209BFA);
    --step-dot-item-tail-disabled-color: var(--color-line1-1, #EEEEEE);
    --step-dot-item-title-wait-color: var(--color-text1-3, #666666);
    --step-dot-item-title-process-color: var(--color-text1-4, #333333);
    --step-dot-item-title-finish-color: var(--color-text1-3, #666666);
    --step-dot-item-title-disabled-color: var(--color-text1-1, #CCCCCC);
    --step-dot-item-node-wait-border-color: var(--color-line1-4, #CCCCCC);
    --step-dot-item-node-wait-background: var(--color-white, #FFFFFF);
    --step-dot-item-node-wait-color: var(--color-text1-2, #999999);
    --step-dot-item-node-process-border-color: var(--color-brand1-6, #209BFA);
    --step-dot-item-node-process-background: var(--color-brand1-6, #209BFA);
    --step-dot-item-node-process-color: var(--color-brand1-6, #209BFA);
    --step-dot-item-node-finish-border-color: var(--color-brand1-6, #209BFA);
    --step-dot-item-node-finish-background: var(--color-white, #FFFFFF);
    --step-dot-item-node-finish-color: var(--color-brand1-6, #209BFA);
    --step-dot-item-node-disabled-border-color: var(--color-line1-2, #E6E6E6);
    --step-dot-item-node-disabled-background: var(--color-white, #FFFFFF);
    --step-dot-item-node-disabled-color: var(--color-line1-1, #EEEEEE);
    --step-dot-vertical-item-node-padding: var(--s-2, 8px);
    --step-dot-vertical-item-body-margin-left: var(--s-4, 16px);
    --step-dot-vertical-item-title-margin-top: var(--s-zero, 0px);
    --step-dot-vertical-item-content-margin-top: var(--s-2, 8px);
    --step-dot-item-content-font-size: var(--font-size-caption, 12px);
    --step-dot-item-content-color: var(--color-text1-3, #666666);
    --step-dot-item-tail-bg-color: var(--color-line1-1, #EEEEEE);
    --step-dot-vertical-item-tail-size: var(--line-1, 1px);
    /* ------------------------ list ------------------------ */
    --list-size-s-title-font-size: var(--font-size-subhead, 16px);
    --list-size-s-title-font-weight: var(--font-weight-3, bold);
    --list-size-s-item-padding-lr: var(--s-zero, 0px);
    --list-size-s-item-padding-tb: var(--s-2, 8px);
    --list-size-s-item-media-margin: var(--s-2, 8px);
    --list-size-s-item-content-font-size: var(--font-size-body-1, 14px);
    --list-size-s-item-content-line-height: var(--font-lineheight-1, 1.3);
    --list-size-s-item-title-font-weight: var(--font-weight-2, normal);
    --list-size-s-item-title-font-size: var(--font-size-body-2, 14px);
    --list-size-s-item-title-line-height: var(--font-lineheight-2, 1.5);
    --list-size-m-title-font-size: var(--font-size-title, 20px);
    --list-size-m-title-font-weight: var(--font-weight-3, bold);
    --list-size-m-item-padding-lr: var(--s-zero, 0px);
    --list-size-m-item-padding-tb: var(--s-4, 16px);
    --list-size-m-item-media-margin: var(--s-2, 8px);
    --list-size-m-item-content-font-size: var(--font-size-body-2, 14px);
    --list-size-m-item-content-line-height: var(--font-lineheight-2, 1.5);
    --list-size-m-item-title-font-size: var(--font-size-subhead, 16px);
    --list-size-m-item-title-line-height: var(--font-lineheight-2, 1.5);
    --list-size-m-item-title-font-weight: var(--font-weight-2, normal);
    --list-divider-color: var(--color-line1-2, #E6E6E6);
    --list-title-color: var(--color-text1-4, #333333);
    --list-content-color: var(--color-text1-3, #666666);
    --list-extra-color: var(--color-text1-2, #999999);
    /* ------------------------ avatar ------------------------ */
    --avatar-size-large: var(--s-13, 52px);
    --avatar-size-medium: var(--s-10, 40px);
    --avatar-size-small: var(--s-7, 28px);
    --avatar-border-radius: var(--corner-1, 3px);
    /* ------------------------ date-picker ------------------------ */
    --date-picker-panel-footer-padding-lr: var(--s-5, 20px);
    --date-picker-panel-footer-padding-tb: var(--s-2, 8px);
    --date-picker-panel-background: var(--color-white, #FFFFFF);
    --date-picker-panel-time-panel-separator-color: var(--color-line1-2, #E6E6E6);
    --date-picker-calendar-icon: var(--icon-content-calendar, "");

    /* 兼容0.x */
    --line-0: var(--line-zero);
    --shadow-0: var(--shadow-zero);
    --corner-right-angle: var(--corner-zero);
    --s-0: var(--s-zero);
}