@import '@alifd/theme-design-pro/variables.css';
@import './variables.css';
@import '@alifd/theme-design-pro/dist/next.var.css';


body {
  -webkit-font-smoothing: antialiased;
}

.next-loading {
  position: unset;
}